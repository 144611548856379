import resolveConfig from 'tailwindcss/resolveConfig';

export const tailwindConfig = () => {
  return resolveConfig('./src/css/tailwind.config.js')
}

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

export const createReferalObject = (userData, formData, clinicalInfo) => {
  return {
      rf: "true",
      secretaryEmail: userData.secretaryEmail,
      secretaryName: userData.secretaryName,
      secretaryTelephone: userData.secretaryTelephone,
      payment: formData.payment,
      paymentMethod: formData.paymentMethod,
      provider: formData.provider,
      address: formData.address,
      postalCode: formData.postalCode,
      city: formData.city,
      dob: formData.dob,
      date: formData.date,
      gender: formData.gender,
      phoneNumber: formData.phoneNumber,
      policyNumber: formData.policyNumber,
      email: formData.email,
      title: formData?.title,
      firstName: formData.firstName,
      lastName: formData.lastName,
      consultant: formData.consultant,
      clinicalIndication: formData.clinicalIndication,
      clinicalInfo: clinicalInfo,
      modality: formData?.modality,
      eGFR: formData.eGFR,
      metalImplant: formData.metalImplant,
      eyeInjury: formData.eyeInjury,
      patient: formData._id,
      mobility: formData.mobility,
      pregnancyStatus: formData.pregnancyStatus,
      pending: "true",
      pdfURL: formData.pdfURL,
      pdfUrls: formData?.pdfUrls || [],
      createdAt: Date.now()
  };
};


export const validateSteps = (formData, checkboxes, currentStep, steps, toast) => {
  if (
      currentStep < 1 ||
      currentStep > steps.length ||
      !steps[currentStep - 1]
  ) {
      return false;
  }

  const currentStepFields = steps[currentStep - 1].fields;
  const stepErrors = {};
  let isValid = true;

  currentStepFields?.forEach((field) => {
      const fieldValue = formData[field];
      if (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0)) {
          stepErrors[field] = "Required field";
          isValid = false;
      }
  });

  if (formData.clinicalIndication === undefined || formData.clinicalIndication === "") {
      toast.dismiss();
      toast("Please enter any known clinical indication.");
      return false;
  }

  if (
      checkboxes?.filter((datas) => datas?.checked === true).length === undefined ||
      checkboxes?.filter((datas) => datas?.checked === true).length === 0
  ) {
      checkboxes?.filter((datas) => datas?.checked === true);
      toast.dismiss();
      toast("Please select at least one option");
      return false;
  }

  return isValid;
};

export const isInvalidFormData = (formData) => {
  return ['mobility', 'eyeInjury', 'metalImplant', 'pregnancyStatus'].some(key => {
      if (key === 'pregnancyStatus') {
          return formData[key] === undefined || formData[key].trim() === '';
      } else {
          return formData[key] === undefined;
      }
  });
};
