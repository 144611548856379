// MultiStepForm.js

import React, { useEffect, useState } from "react";
import axios from "../config/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Textarea, Select } from "@chakra-ui/react";
import Lottie from 'lottie-react';
import FormData from 'form-data';
import { Modality, steps, formatDate } from "../utils/seed"
import { getUser, generatePdfFiles } from "../utils/generatePdf";
import { createReferalObject ,validateSteps,isInvalidFormData} from "../utils/Utils";
import animationData from '../Assets/loader.json';

const MultiStepForm = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState("");
    const [errors, setErrors] = useState({});
    const [id, setId] = useState("");
    const [rid, setRid] = useState("");
    const [loading, setLoading] = useState(false)
    const [pdfurls, setpdfUrls] = useState(null);
    const [referralStatus, setReferralStatus] = useState(false)
    const [Referral, setReferrlchange] = useState();
    const [userData, setUserData] = useState({});
    const [checkboxes, setCheckboxes] = useState(Modality);
    const [clinicalData, setclinicalData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        getUser();
        setId(location?.state || "");
    }, [location]);

    const fetchData = async () => {
        try {
            if (id) {
                try {
                    const patientResponse = await axios.get(`patients/${id}`);
                    const patientData = patientResponse?.data?.data;
                    setFormData(patientData || {});
                    return;
                } catch (patientError) {
                    console.error("Error fetching patient data:", patientError);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const fetchReferralData = async () => {
        try {
            let referral_id = localStorage.getItem("rid")
            if (referral_id) {
                try {
                    const referralResponse = await axios.get(`referrals/${referral_id}`);
                    const referralData = referralResponse?.data?.data;
                    setReferralStatus(referralData.rf)
                    setFormData(referralData || {});
                } catch (referralError) {
                    console.error("Error fetching referral data:", referralError);

                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchReferralData();
        fetchData();
    }, [id]);


    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        localStorage.setItem(name, value);
        setFormData((prevData) => ({
            ...prevData,
            [name]:
                type === "checkbox"
                    ? handleCheckboxChange(name, value, checked, prevData)
                    : value,
        }));
    };

    const handleCheckboxChange = (name, value, checked, prevData) => {
        const currentOptions = prevData[name] || [];
        if (checked) {
            return [...currentOptions, value];
        } else {
            return currentOptions?.filter((option) => option !== value);
        }
    };

    const validateStep = () => {
        return validateSteps(formData, checkboxes, currentStep, steps, toast)
    };

    const updateStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handleNextStep = async () => {
        if (validateStep()) {
            try {
                if (!location.state) {
                    toast("Patient has not been found. Please create a patient first.")
                    navigate("/patient_list")
                }
                const patientData = {
                    ...formData
                };
                if (currentStep === 2) {
                    patientData.safety = {
                        pregnancy: formData.gender === "yes" ? "Yes" : "No",
                        eGFR: formData.eGFR || null,
                    };
                }
                if (currentStep === 1) {
                    patientData.bodyPart = formData.bodyPart;
                    patientData.clinicalIndication =
                        formData.clinicalIndication;
                    setLoading(true);
                    setTimeout(() => {
                        updateStep();
                        setLoading(false);
                    }, 500);
                }
            } catch (error) {
                toast(error?.response?.data?.message || "An error occurred while saving data.");
            }
        }
    };

    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleCancel = () => {
        navigate("/all_patients");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleInputTextChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            const data = {
                ...Referral,
                rf: "false"
            }
            const response1 = await axios.patch(`referrals/${rid} `, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            localStorage.removeItem('rid')
            setTimeout(() => {
                toast("Your referral has been created and a copy has been emailed to you. A confirmation email has also been sent to the patient, our bookings team will be in touch with the patient directly. You can download the PDF referral below or you can find it under your referrals page.");
                updateStep();
                setLoading(false);
            }, 500);


        } catch (error) {
            toast("An error occurred while processing your request. Please try again later.");
        }


    }

    const handleEdit = (rid) => {
        setCurrentStep((prevStep) => prevStep - 2);
        localStorage.setItem("rid", rid)
    };



    const handleSendEmail = async () => {
       
        try {
            if (
                isInvalidFormData(formData)
            ) {
                toast.error("Please answer all required questions to proceed.");
            } else {
                setLoading(true);
                const userId = JSON.parse(sessionStorage.getItem("data"))?.userData?._id;
                const response = await axios.get(`/users/${userId}`);
                const userData = response?.data?.data;
                setUserData(userData);

                if (userData?.jobTitle === "") {
                    toast("Please fill out all required fields to continue.");
                    navigate("/profile");
                } else {
                    const formDatas = new FormData();
                    const PdfFiles = await generatePdfFiles(checkboxes, userData, formData, formDatas);
                    const {clinicalInfo } = PdfFiles;
                    const data = createReferalObject(userData, formData, clinicalInfo);

                    if (formData?.date) {
                        formDatas.append("date", formData?.date);
                    }
                    Object.entries(data).forEach(([key, value]) => {
                        if (Array.isArray(value)) {
                            value.forEach((v, index) => {
                                formDatas.append(`${key}[${index}]`, v);
                            });
                        } else {
                            formDatas.append(key, value);
                        }
                    });
                    let response1;

                    if (location?.state) {
                        if (referralStatus === true) {
                            response1 = await axios.patch(`referrals/${id} `, formDatas, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            });

                        } else {
                            response1 = await axios.post("referrals/", formDatas, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            });
                        }

                        setReferrlchange(response1.data.data)
                        setTimeout(() => {
                            updateStep();
                            setLoading(false);
                        }, 500);
                    } else {
                        toast("Patient has not been found. Please create a patient first.")
                        navigate("/patient_list")
                    }
                    setRid(response1?.data?.data._id)
                    setpdfUrls(response1?.data?.data?.pdfUrls)
                    setclinicalData(response1?.data?.data?.clinicalInfo)

                }
            }
        } catch (error) {
            toast("An error occurred while processing the request.");

        }



    };

    const handleCheckboxChanges = (id) => {
        const updatedCheckboxes = checkboxes.map((checkbox) => {
            if (checkbox.id === id) {
                if (checkbox.pairs) {

                    return {
                        ...checkbox,
                        checked: !checkbox.checked,
                        pairs: checkbox.checked ? [{ value1: "", value2: "" }] : checkbox.pairs
                    };
                } else {
                    return {
                        ...checkbox,
                        checked: !checkbox.checked
                    };
                }
            }
            return checkbox; 
        });

        setCheckboxes(updatedCheckboxes);
        let setData = updatedCheckboxes?.filter(data => data.checked === true)?.map(data => ({
            name: data.name,
            options: data.pairs || []
        })) || [];
        setFormData((prevData) => ({
            ...prevData,
            ["modality"]: [...setData]
        }));

    };


    const handleAddPair = (checkboxId) => {
        const updatedCheckboxes = checkboxes.map(checkbox =>
            checkbox.id === checkboxId
                ? { ...checkbox, pairs: [{ value1: "", value2: "" }, ...(checkbox.pairs || [])], }
                : checkbox
        );
        setCheckboxes(updatedCheckboxes);
    };

    const handleChange = (checkboxId, pairIndex, field, value) => {
        const updatedCheckboxes = checkboxes.map(checkbox =>
            checkbox.id === checkboxId
                ? {
                    ...checkbox,
                    pairs: (checkbox.pairs || []).map((pair, index) =>
                        index === pairIndex ? { ...pair, [field]: value } : pair
                    )
                }
                : checkbox
        );
        setCheckboxes(updatedCheckboxes);
        let setData = updatedCheckboxes?.filter(data => data.checked === true)?.map(data => ({
            name: data.name,
            options: data.pairs || []
        })) || [];
        setFormData((prevData) => ({
            ...prevData,
            ["modality"]: [...setData]
        }));
    };

    const handleDeletePair = (checkboxId, pairIndex) => {
        const updatedCheckboxes = checkboxes.map(checkbox =>
            checkbox.id === checkboxId
                ? {
                    ...checkbox,
                    pairs: (checkbox.pairs || []).filter((pair, index) => index !== pairIndex)
                }
                : checkbox
        );
        setCheckboxes(updatedCheckboxes);
    };



    return (
        <>
            {loading ? (
                <>
                    <div className="p-36  flex justify-center items-center mt-20">
                        <Lottie animationData={animationData} />
                    </div>
                </>
            ) : (
                <div>
                    {
                        window.innerWidth < 500 ? (
                            <div className="text-center text-white p-4 bg-red-500">
                                Sorry! this portal is only operatable through desktop devices.
                            </div>
                        ) : (


                            <div
                                className="mx-auto my-5"
                                style={{ width: "80%", backgroundColor: "white" }}

                            >

                                <ToastContainer />
                                <div className="mb-4 w-full mt-5">

                                    <div className="flex space-x-2 w-full">
                                        {steps.map((step, index) => (
                                            <React.Fragment key={step.id}>
                                                {index > 0 && (
                                                    <div
                                                        className="flex items-center"
                                                        style={{ flex: "0.6 1" }}
                                                    >
                                                        <div className="flex-1 h-0.5 bg-gray-300"></div>
                                                    </div>
                                                )}
                                                <div
                                                    className={`flex items-center justify-between ps-2  py-1 border -black rounded-full ${step.id === currentStep
                                                        ? "font-lg hover:scale-105 transition-transform duration-300 transform origin-center"
                                                        : "opacity-50 cursor-not-allowed"
                                                        } `}
                                                >
                                                    <span
                                                        className={`border-2 text-white font-bold rounded-full p-1 px-3 ${step.id === currentStep
                                                            ? "bg-[#2CA1C8]"
                                                            : "bg-[#D0D5DD]"
                                                            } `}
                                                    >
                                                        {step.id}
                                                    </span>{" "}
                                                    <span className="text-clip  inline-flex justify-end px-4" style={{ color: "#101828", fontSize: "14px" }}>
                                                        {step?.stepName}
                                                    </span>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    {steps.map((step, index) => (
                                        <div
                                            key={step.id}
                                            className={currentStep === step.id ? "" : "hidden"}
                                        >
                                            {currentStep !== steps.length && (

                                                <h2 className="text-2xl font-bold mb-4">
                                                    {step.stepName}
                                                </h2>

                                            )}
                                            <div
                                                className={
                                                    step.stepName === "Select Modality"
                                                        ? "grid  gap-4"
                                                        : ""
                                                }
                                            >

                                                {step.stepName === "Select Modality" && checkboxes?.map((checkbox, index) => (
                                                    <div className="flex" >
                                                        <div key={index} style={{ width: "30%" }}>
                                                            <div className="flex col items-center space-x-2">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={checkbox.checked}
                                                                    onChange={() => handleCheckboxChanges(checkbox.id)}
                                                                    className="mr-2 w-5 h-5"
                                                                    style={{
                                                                        backgroundColor:
                                                                            formData[
                                                                                step.field
                                                                            ]

                                                                                ? "#F5F4F0"
                                                                                : "inehrit",
                                                                    }}

                                                                />
                                                                <label
                                                                    className="block text-base font-medium text-gray-700"
                                                                    htmlFor={checkbox.name}
                                                                >
                                                                    {checkbox.name}
                                                                </label>

                                                            </div>

                                                        </div>
                                                        <div >
                                                            {checkbox.checked && checkbox.pairs &&
                                                                checkbox.pairs.map((pair, index) => (
                                                                    <div key={index} className="flex mb-2 gap-2"  >
                                                                        <div>
                                                                            <Select
                                                                                style={{ width: '200px' }}
                                                                                disabled={checkbox.checked && index === 0 ? false : true}
                                                                                value={pair.value1}
                                                                                onChange={(e) => {
                                                                                    handleChange(checkbox.id, index, 'value1', e.target.value);

                                                                                }}
                                                                            >
                                                                                <option key={index} value="" >Select Category</option>
                                                                                {checkbox.sub_option && checkbox.sub_option.map((option, index) => (
                                                                                    <option key={index} value={option.label}  >{option.label}</option>
                                                                                ))}
                                                                            </Select>
                                                                        </div>
                                                                        <div>
                                                                            {pair.value1 !== "Other" ?
                                                                                <Select
                                                                                    style={{ width: '200px' }}

                                                                                    disabled={checkbox.checked && index === 0 ? false : true}
                                                                                    value={pair.value2}
                                                                                    onChange={(e) => handleChange(checkbox.id, index, 'value2', e.target.value)}
                                                                                >
                                                                                    <option key={index} value=""  >Select Sub-Category</option>
                                                                                    {pair.value1 &&
                                                                                        checkboxes.find(c => c.id === checkbox.id)?.sub_option.find(option => option.label === pair.value1)?.sub_options?.map((option, index) => (
                                                                                            <option key={index} value={option.value} >{option.value}</option>
                                                                                        ))
                                                                                    }
                                                                                </Select> :
                                                                                <input
                                                                                    type="text"
                                                                                    name="specify"
                                                                                    value={pair.value2}
                                                                                    disabled={checkbox.checked && index === 0 ? false : true}
                                                                                    onChange={(e) => handleChange(checkbox.id, index, 'value2', e.target.value)}
                                                                                    className="py-2 px-2 border rounded-lg"
                                                                                />

                                                                            }
                                                                        </div>

                                                                        {index !== 0 && checkbox.pairs.length > 1 && (

                                                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleDeletePair(checkbox.id, index)}>
                                                                                <g clip-path="url(#clip0_1942_6267)">
                                                                                    <path d="M28.8008 20C28.8008 20.44 28.4408 20.8 28.0008 20.8H20.0008C20.0008 20.8 20.0408 20.8 19.6008 20.8H19.2008H12.0008C11.5608 20.8 11.2008 20.44 11.2008 20C11.2008 19.56 11.5608 19.2 12.0008 19.2H20.8008H28.0008C28.4408 19.2 28.8008 19.56 28.8008 20ZM35.2008 20C35.2008 28.4 28.4008 35.2 20.0008 35.2C11.6008 35.2 4.80078 28.4 4.80078 20C4.80078 11.6 11.6008 4.80005 20.0008 4.80005C28.4008 4.80005 35.2008 11.6 35.2008 20ZM33.6008 20C33.6008 12.48 27.5208 6.40005 20.0008 6.40005C12.4808 6.40005 6.40078 12.48 6.40078 20C6.40078 27.52 12.4808 33.6 20.0008 33.6C27.5208 33.6 33.6008 27.52 33.6008 20Z" fill="#2CA1C8" />
                                                                                    <path d="M601.601 -484V189.6H-111.999V-484H601.601ZM604.801 -487.2H-115.199V192.8H604.801V-487.2Z" fill="#0000FF" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_1942_6267">
                                                                                        <rect width="40" height="40" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>


                                                                        )}


                                                                        {checkbox.checked && index === 0 &&
                                                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => !pair.value1 || !pair.value2 ? null : handleAddPair(checkbox.id)}>
                                                                                <g clip-path="url(#clip0_1942_6264)">
                                                                                    <path d="M28.8008 20C28.8008 20.44 28.4408 20.8 28.0008 20.8H20.8008V28C20.8008 28.4401 20.4408 28.8 20.0008 28.8C19.5608 28.8 19.2008 28.4401 19.2008 28V20.8H12.0008C11.5608 20.8 11.2008 20.44 11.2008 20C11.2008 19.56 11.5608 19.2 12.0008 19.2H19.2008V12C19.2008 11.56 19.5608 11.2 20.0008 11.2C20.4408 11.2 20.8008 11.56 20.8008 12V19.2H28.0008C28.4408 19.2 28.8008 19.56 28.8008 20ZM35.2008 20C35.2008 28.4 28.4008 35.2 20.0008 35.2C11.6008 35.2 4.80078 28.4 4.80078 20C4.80078 11.6 11.6008 4.80005 20.0008 4.80005C28.4008 4.80005 35.2008 11.6 35.2008 20ZM33.6008 20C33.6008 12.48 27.5208 6.40005 20.0008 6.40005C12.4808 6.40005 6.40078 12.48 6.40078 20C6.40078 27.52 12.4808 33.6 20.0008 33.6C27.5208 33.6 33.6008 27.52 33.6008 20Z" fill="#2CA1C8" />
                                                                                    <path d="M601.601 -484V189.6H-111.999V-484H601.601ZM604.801 -487.2H-115.199V192.8H604.801V-487.2Z" fill="#0000FF" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_1942_6264">
                                                                                        <rect width="40" height="40" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>


                                                                        }
                                                                    </div>
                                                                ))}

                                                        </div>
                                                        <div>

                                                        </div>

                                                    </div>
                                                ))}


                                                {step.stepName === "Safety" && (
                                                    <>
                                                        <p className="font-semibold my-2">
                                                            1. Safety check (as recommended by
                                                            the MHRA, the referring clinician is
                                                            required to assess patient safety
                                                            for MRI scans).
                                                        </p>
                                                        <p className="my-2">
                                                            Cardiac pacemakers, artificial heart
                                                            valves, cochlear implants, cerebral
                                                            aneurysm clips are contra-indicated
                                                            for MRI.
                                                        </p>
                                                    </>
                                                )}
                                                {step.stepName === "Safety" && (
                                                    <>
                                                        {step.labels &&
                                                            step.labels.map(
                                                                (label, labelIndex) => (
                                                                    <div
                                                                        key={labelIndex}
                                                                        className="mb-4 flex"
                                                                    >
                                                                        <label className="block text-sm font-medium text-gray-700 flex-1">
                                                                            {label}<span className="text-red-500">*</span>
                                                                        </label>
                                                                        <div className="flex items-center space-x-2">
                                                                            <label className="flex items-center mr-2">
                                                                                <input
                                                                                    type="radio"
                                                                                    name={
                                                                                        step
                                                                                            .fields[
                                                                                        labelIndex
                                                                                        ]
                                                                                    }
                                                                                    value="Yes"
                                                                                    checked={
                                                                                        formData[
                                                                                        step
                                                                                            .fields[
                                                                                        labelIndex
                                                                                        ]
                                                                                        ] ===
                                                                                        "Yes"
                                                                                    }
                                                                                    onChange={
                                                                                        handleInputChange
                                                                                    }
                                                                                />
                                                                                <span className="ml-1">
                                                                                    Yes
                                                                                </span>
                                                                            </label>
                                                                            <label className="flex items-center">
                                                                                <input type="radio"
                                                                                    name={
                                                                                        step
                                                                                            .fields[
                                                                                        labelIndex
                                                                                        ]
                                                                                    }
                                                                                    value="No"
                                                                                    checked={
                                                                                        formData[
                                                                                        step
                                                                                            .fields[
                                                                                        labelIndex
                                                                                        ]
                                                                                        ] ===
                                                                                        "No"
                                                                                    }
                                                                                    onChange={
                                                                                        handleInputChange
                                                                                    }
                                                                                />
                                                                                <span className="ml-1">
                                                                                    No
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        {errors[currentStep] &&
                                                                            errors[currentStep][
                                                                            step.fields[
                                                                            labelIndex
                                                                            ]
                                                                            ] && (
                                                                                <p className="text-red-500 text-sm mt-1">
                                                                                    {
                                                                                        errors[
                                                                                        currentStep
                                                                                        ][
                                                                                        step
                                                                                            .fields[
                                                                                        labelIndex
                                                                                        ]
                                                                                        ]
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                    </div>
                                                                )
                                                            )}
                                                        <p className="font-semibold my-2">
                                                            2. Contrast investigations.
                                                        </p>
                                                        <p className="my-2 font-semibold ">
                                                            For patients above 65 years of age
                                                            or with any known problems with
                                                            kidney function, serum creatinine
                                                            level or eGFR must be available
                                                            prior to imaging.
                                                        </p>
                                                        <div className="flex items-center">
                                                            <p className="me-3">
                                                                Serum creatinine/eGFR reading:{" "}
                                                            </p>
                                                            <input
                                                                type="number"
                                                                name="eGFR"
                                                                value={formData.eGFR || ""}
                                                                onChange={(e) =>
                                                                    handleInputTextChange(
                                                                        "eGFR",
                                                                        e.target.value
                                                                    )
                                                                }
                                                                className="py-2 px-2 border rounded-lg"
                                                            />
                                                        </div>
                                                        <div className="flex items-center mt-2">
                                                            <p className="me-3">Date taken: </p>
                                                            <input
                                                                type="date"
                                                                name="date"
                                                                value={formData.date}
                                                                onChange={(e) =>
                                                                    handleInputTextChange(
                                                                        "date",
                                                                        e.target.value
                                                                    )
                                                                }
                                                                className="py-2 px-2 border rounded-lg"
                                                            />
                                                        </div>



                                                    </>
                                                )}
                                                <div className="flex flex-col my-5">
                                                    {step.stepName === "Safety" && (

                                                        <>
                                                            <p className="font-semibold my-2">3. Pregnancy<span className="text-red-500">*</span></p>
                                                            <div className="mb-4 w-1/3">
                                                                <select
                                                                    type="text"
                                                                    name="pregnancyStatus"
                                                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                                                    onChange={handleInputChange}
                                                                    value={formData.pregnancyStatus || ""}
                                                                >
                                                                    <option value="" disabled>
                                                                        Select pregnancy
                                                                    </option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                    <option value="Not applicable">Not applicable</option>
                                                                    <option value="Unknown">Unknown</option>
                                                                </select>
                                                                {errors.pregnancyStatus && (
                                                                    <p className="text-red-500 text-sm mt-1">
                                                                        {errors.pregnancyStatus}
                                                                    </p>
                                                                )}
                                                                <div
                                                                    className=" mt-4" >
                                                                    <p className="font-semibold my-2">4. Mobility<span className="text-red-500">*</span></p>


                                                                    <select
                                                                        type="text"
                                                                        name="mobility"
                                                                        onChange={handleInputChange}
                                                                        className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                                                        value={formData.mobility || ""}
                                                                    >

                                                                        <option value="" disabled>
                                                                            Select Mobility
                                                                        </option>
                                                                        <option value="Mobile">Mobile</option>
                                                                        <option value="Non-Mobile">Non-Mobile</option>

                                                                    </select>
                                                                </div>
                                                            </div>

                                                        </>

                                                    )}

                                                    {step.stepName === "Finish" && (
                                                        <div className=" justify-center items-center">
                                                            <div className="flex justify-center">
                                                                <div className="px-4 py-2 bg-[#003942] text-white rounded-md mt-4" style={{ fontSize: "30px" }}>
                                                                    Success!</div>
                                                            </div>

                                                            <div className=" h-full border shadow-lg mb-160px p-6 rounder-2xl text-center mt-2">


                                                                <p className="pb-4">
                                                                    Your referral has been
                                                                    created and a copy has
                                                                    been emailed to you. A confirmation email has also been sent to the patient, our bookings team will be in touch with the patient directly. You
                                                                    can download the PDF
                                                                    referral below or you can
                                                                    find it under your referrals
                                                                    page.
                                                                </p>
                                                                {pdfurls?.map((data, index) => {
                                                                    return (
                                                                        <div className="flex justify-center">
                                                                            <a

                                                                                href={data}
                                                                                target="_blank"
                                                                                className="px-4  py-2 bg-[#003942] text-white rounded-md mt-4"
                                                                            >
                                                                                Download Referral for {clinicalData?.[index]}
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })}

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {step.stepName === "Select Modality" && (
                                                <>
                                                    <div className="flex justify-between gap-6 w-4/5 mt-3 border-1 container">


                                                        <div className="mb-4 w-1/2">
                                                            <label className="block text-sm font-medium text-gray-700">
                                                                Clinical Indication*
                                                            </label>
                                                            <Textarea
                                                                onChange={(e) =>
                                                                    handleInputTextChange(
                                                                        "clinicalIndication",
                                                                        e.target.value
                                                                    )
                                                                }
                                                                type="text"
                                                                name="clinicalIndication"
                                                                value={formData.clinicalIndication} // Bind the value here
                                                                className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                                                placeholder="Please enter any known clinical indication"
                                                            />
                                                            {errors[currentStep] &&
                                                                errors[currentStep][
                                                                step.field
                                                                ] && (
                                                                    <p className="text-red-500 text-sm mt-1">
                                                                        {
                                                                            errors[currentStep][
                                                                            step.field
                                                                            ]
                                                                        }
                                                                    </p>
                                                                )}
                                                        </div>

                                                    </div>
                                                </>
                                            )}
                                            <div>
                                                {step.stepName === "Summary" && (
                                                    <>
                                                        <h2 className="block font-none my-2 font-semibold">
                                                            Modality<span className="text-red-500">*</span>
                                                        </h2>
                                                        <div className="flex mb-0">
                                                            <div className="grid grid-cols-1 gap-2 mt-0 mb-[-12px]">

                                                                {checkboxes?.filter((datas) => datas.checked === true)?.map(data => {
                                                                    return (
                                                                        data.pairs ? data?.pairs.map(pair => {
                                                                            return pair?.value1 && pair?.value2 &&
                                                                                (
                                                                                    <div
                                                                                        key={index}
/*                                                                    
*/                                                                    className={`col-span-1 ${index % 4 === 1 ? 'my-1' : ''} ${index % 4 === 3 ? 'ml-0' : ''} relative overflow-hidden group rounded-lg p-2`}
                                                                                    >

                                                                                        <span
                                                                                            className="text-gray-700 flex-1"
                                                                                        >
                                                                                            {`${data.name} >  ${pair.value1}  >   ${pair.value2}`}
                                                                                        </span>
                                                                                    </div>)
                                                                        }) : <div
                                                                            key={index}
                                                                            className={`col-span-1 ${index % 4 === 1 ? 'my-1' : ''} ${index % 4 === 3 ? 'ml-0' : ''} relative overflow-hidden group rounded-lg p-2`}
                                                                        >

                                                                            <span
                                                                                className="text-gray-700 flex-1"
                                                                            >
                                                                                {data.name}
                                                                            </span>
                                                                        </div>)
                                                                })}



                                                            </div>




                                                        </div>
                                                        <div className="flex justify-between gap-6 w-4/5 mt-3 container">
                                                            <div className="mb-4 w-1/2">
                                                                <h2 className="block font-none my-2 font-semibold">
                                                                    Clinical Indication<span className="text-red-500">*</span>
                                                                </h2>

                                                                <p style={{ color: "#374151" }}>{formData.clinicalIndication}</p>
                                                                {errors[currentStep] &&
                                                                    errors[currentStep][step.field] && (
                                                                        <p className="text-red-500 text-sm mt-1 font-medium">
                                                                            {errors[currentStep][step.field]}
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {step.stepName === "Summary" && (
                                                    <>
                                                        <p className="my-2   text-black-600">
                                                            1. Safety check (as recommended by
                                                            the MHRA, the referring clinician is
                                                            required to assess patient safety
                                                            for MRI scans).

                                                        </p>
                                                        <p className="my-2 text-gray-700">
                                                            Cardiac pacemakers, artificial heart
                                                            valves, cochlear implants, cerebral
                                                            aneurysm clips are contra-indicated
                                                            for MRI.
                                                        </p>
                                                    </>
                                                )}

                                                {step.stepName === "Summary" && (
                                                    <>
                                                        {step.labels &&
                                                            (
                                                                <div
                                                                    className="mb-4 "
                                                                >
                                                                    <div className="flex">
                                                                        <label className="block  text-gray-700 flex-1">
                                                                            Does the patient have a metal implant or pacemaker?<span className="text-red-500">*</span>
                                                                        </label>
                                                                        <div className="flex items-center space-x-2">
                                                                            <label className="flex items-center mr-2">
                                                                                <span className="ml-1 py-2 px-2 text-gray-700">
                                                                                    {formData.metalImplant}
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex">
                                                                        <label className="block  text-gray-700 flex-1">
                                                                            Has the patient ever had an injury to the eye involving a metallic object? <span className="text-red-500">*</span>
                                                                        </label>
                                                                        <div className="flex items-center space-x-2">
                                                                            <label className="flex items-center mr-2">
                                                                                <span className="ml-1 py-2 px-2 text-gray-700">
                                                                                    {formData.eyeInjury}
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-center mt-2 font-semibold">
                                                                        <p className="me-3 ">Mobility: </p>
                                                                        <span className="ml-1 py-2 px-2  font-normal text-gray-700" >
                                                                            {formData.mobility}
                                                                        </span>

                                                                    </div>
                                                                    <div className="flex items-center mt-2 font-semibold">
                                                                        <p className="me-3 ">Pregnancy: </p>
                                                                        <span className="ml-1 py-2 px-2 font-normal text-gray-700">
                                                                            {formData.pregnancyStatus}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            )}
                                                        <p className="my-2 font-semibold">
                                                            2. Contrast investigations.
                                                        </p>
                                                        <p className="my-2 text text-gray-700">
                                                            For patients above 65 years of age
                                                            or with any known problems with
                                                            kidney function, serum creatinine
                                                            level or eGFR must be available
                                                            prior to imaging.
                                                        </p>
                                                        <div className="flex items-center mt-4">
                                                            <p className="me-3 font-semibold">
                                                                Serum creatinine/eGFR reading:{" "}
                                                            </p>
                                                            {formData.eGFR ? (
                                                                <span className="py-2 px-2 ">
                                                                    {formData.eGFR}
                                                                </span>
                                                            ) : null}
                                                        </div>



                                                        <div className="flex items-center mt-2 col-2">
                                                            <p className="me-3 font-semibold">Date taken: </p>
                                                            {formData.date ? (
                                                                <span className="ml-1 py-2 px-2">
                                                                    {formatDate(formData.date)}
                                                                </span>
                                                            ) : null}
                                                        </div>

                                                    </>
                                                )}



                                            </div>


                                            <div className="mt-4 flex justify-end">
                                                {currentStep > 2 &&
                                                    currentStep !== steps.length && (
                                                        <button
                                                            type="button"
                                                            onClick={() => handleEdit(rid)} // Pass rid parameter here
                                                            className="px-4 mx-4 py-2 ml-4 bg-white text-black border border-black rounded-md"
                                                        >
                                                            Edit referral
                                                        </button>


                                                    )}

                                                {currentStep > 2 &&
                                                    currentStep !== steps.length && (
                                                        <button
                                                            type="button"
                                                            onClick={handleConfirm}
                                                            className="flex px-4 py-2 bg-[#003942] text-white rounded-md pd-30"
                                                        >
                                                            Confirm referral

                                                        </button>
                                                    )}
                                            </div>
                                            <div className="mt-4 flex justify-end">
                                                {currentStep < steps.length &&
                                                    step.stepName === "Safety" && (
                                                        <button
                                                            type="button"
                                                            onClick={handlePreviousStep}
                                                            className="px-4 mx-4 py-2 ml-4 bg-white text-black border border-black rounded-md"
                                                        >
                                                            Previous
                                                        </button>
                                                    )}
                                                {currentStep < steps.length &&
                                                    step.stepName === "Select Modality" && (
                                                        <>
                                                            <div className="mt-4 flex justify-end me-5">
                                                                <button
                                                                    type="button"
                                                                    onClick={handleCancel}
                                                                    className="px-4 py-2 bg-[#003942] text-white rounded-md"
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={handleNextStep}
                                                                    className=" flex px-4 ms-4 py-2 bg-[#003942] text-white rounded-md"
                                                                >
                                                                    Continue to Safety Checks
                                                                    <div>


                                                                    </div>

                                                                </button>



                                                            </div>

                                                        </>
                                                    )}
                                                {currentStep < steps.length &&
                                                    step.stepName === "Safety" && (
                                                        <button
                                                            type="button"
                                                            onClick={handleSendEmail}
                                                            className=" flex px-4 py-2 bg-[#003942] text-white rounded-md pd-30"
                                                        >
                                                            Proceed to Confirmation
                                                        </button>
                                                    )}
                                            </div>


                                        </div>
                                    ))}
                                </form>
                            </div>

                        )}
                </div>
            )}

        </>
    );
};

export default MultiStepForm;
