import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../config/axios";
import { useLocation, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import { Audio } from 'react-loader-spinner'
import Lottie from 'lottie-react';

import { useEffect } from "react";
import Footer from "../components/Footer";
import Logo from "../Assets/images/logo.png";
import { Vortex } from 'react-loader-spinner'
import animationData from '../Assets/loader.json';


const PatientForm = () => {
    const [formData, setFormData] = useState({});
    const [userData, setUserData] = useState({});
    const [save, setSave] = useState(false)
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)

    const [id, setId] = useState();
    const [counsultantId, setCounsultantId] = useState();
    const [patientId, setPatientId] = useState(null);
    let role = JSON.parse(sessionStorage.getItem("data")).userData?.role;
    useEffect(() => {
        const id = JSON.parse(sessionStorage.getItem("data"))?.userData._id;
        console.log(id);
        axios.get(`/users/${id}`).then((response) => {
            const userData = response.data?.data;
            console.log("response", userData.statusId);

            const status = JSON.parse(sessionStorage.getItem("data"))?.userData
                .statusId;
            const name = userData.name;
            console.log(name);

            if (userData.statusId === "inactive") {
                navigate("/");
                setTimeout(() => {
                    toast(
                        `Dear ${name} your is in inactive. Please contact support for assistance.`
                    );
                }, 500);
                return; // Exit the function after navigation
            }
        });
    });

    const navigate = useNavigate();
    const location = useLocation();
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'title' && value !== 'Prefer Not to Say') {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        } else {
            // For other form fields or when "Prefer Not to Say" is selected, set the value directly
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
        console.log(name)
    };
    function formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    }
    const formattedDate = formData.createdAt instanceof Date && !isNaN(formData.createdAt)
        ? formatDate(formData.createdAt)
        : '';
    const validateStep = () => {
        const stepErrors = {};
        let isValid = true;

        if (!formData.title) {
            stepErrors.title = "This field is required";
            isValid = false;
        }
        if (!formData.firstName) {
            stepErrors.firstName = "This field is required";
            isValid = false;
        }

        if (!formData.lastName) {
            stepErrors.lastName = "This field is required";
            isValid = false;
        }
        if (!formData.payment) {
            stepErrors.payment = "This field is required";
            isValid = false;
        }
        if (!formData.dob) {
            stepErrors.dob = "This field is required";
            isValid = false;
        }

        if (!formData.gender) {
            stepErrors.gender = "This field is required";
            isValid = false;
        }
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!formData.email || !formData.email.match(validRegex)) {
            stepErrors.email = "This field is required";
            isValid = false;
        }
        if (!formData.phoneNumber) {
            stepErrors.phoneNumber = "This field is required";
            isValid = false;
        }

        setErrors(stepErrors);
        return isValid;
    };
    /*  const validateStep = () => {
         const stepErrors = {};
         let isValid = true;
 
         if (!formData.title) {
             toast("Title field is required");
             isValid = false;
         }
         if (!formData.firstName) {
             toast("FirstName field is required");
             isValid = false;
             stepErrors;
         }
 
         if (!formData.lastName) {
             toast("LastName field is required");
         }
         if (!formData.payment) {
             toast("Payment field is required");
         }
         if (!formData.dob) {
             toast("Dob field is required");
         }
 
         if (!formData.gender) {
             toast("Gender field is required");
         }
         var validRegex =
             /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
         if (!formData.email || !formData.email.match(validRegex)) {
             toast("Email field is required");
         }
         if (!formData.phoneNumber) {
             toast("PhoneNumber field is required");
         }
 
     }; */

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem("data"))?.userData;
        const id = user?._id;
        setId(id);
    }, []);
    const handleSubmit = async (e, action) => {
        e.preventDefault();


        if (validateStep()) {
            try {

                const userId = JSON.parse(sessionStorage.getItem("data"))?.userData?._id;
                const response = await axios.get(`/users/${userId}`);
                const userData = response?.data?.data;
                setUserData(userData)
                console.log("User data response:", response?.data);
                console.log("User job title:", userData?.jobTitle);

                if (userData?.jobTitle === "") {
                    toast("Please fill out your job title field.");
                    navigate("/profile")
                } else {
                    const name = JSON.parse(sessionStorage.getItem("data"))
                        ?.userData?.name;
                    const pdf = new jsPDF();

                    // Logo
                    const logo = new Image();
                    logo.src = Logo; // Replace with the actual path to your log

                    pdf.addImage(logo, 10, 9, 80, 14);
                    pdf.setFontSize(16);
                    pdf.setFont("bold");
                    // pdf.setFont("normal");

                    // Contacts
                    pdf.setFontSize(15);
                    pdf.setFont("bold");
                    pdf.text("Telephone:", 120, 16);
                    pdf.text("0207 467 6190", 146, 16);
                    pdf.text("Email:", 120, 23);
                    pdf.text("bookings@umegroup.com", 138, 23);
                    pdf.text("17 Harley Street, London W1G 9QH", 120, 30); //

                    // Title
                    pdf.setFontSize(28);
                    pdf.setFont("bold");
                    pdf.text("Imaging Referral Form", 65, 44); // Adjusted Y-coordinate to 51

                    //table
                    autoTable(pdf, {
                        startY: 48,
                        head: [
                            ["Patient details", `Date of referral:${formatDate(formData.date)}`],
                        ],
                        body: [
                            [
                                {
                                    content: `Title: ${formData.title}`,
                                    styles: { cellWidth: "auto" },
                                },
                                {
                                    content: `Address: ${formData.address}`,
                                    styles: { cellWidth: "auto" },
                                },
                            ],
                            [
                                {
                                    content: `First Name: ${formData.firstName}`,
                                    styles: { cellWidth: "auto" },
                                },

                                {
                                    content: `Postcode: ${formData.postalCode}`,
                                    styles: { cellWidth: "auto" },
                                },
                            ],

                            [
                                {
                                    content: `Last Name: ${formData.lastName}`,
                                    styles: { cellWidth: "auto" },
                                },
                                {
                                    content: `Telephone: ${formData.phoneNumber}`,
                                    styles: { cellWidth: "auto" },
                                },
                            ],

                            [
                                {
                                    content: `DOB: ${formatDate(formData.dob)}`,
                                    styles: { cellWidth: "auto" },
                                },
                                {
                                    content: `Email: ${formData.email}`,
                                    styles: { cellWidth: "auto" },
                                },
                            ],

                            [

                                {
                                    content: `Gender: ${formData.gender}`,
                                    styles: { cellWidth: "auto" },
                                },

                                {
                                    content: `Payee: ${formData.payment}`,
                                    styles: { cellWidth: "auto" },
                                },
                            ],


                            [
                                {
                                    content: "Mobility: Mobile",
                                    styles: { cellWidth: "auto" },
                                },


                                {
                                    content: `Insurance company: ${formData.provider || " "}`,
                                    styles: { cellWidth: "auto" },
                                },



                            ],

                            [
                                "",
                                {
                                    content: `Policy number: ${formData.policyNumber || " "}`,
                                    styles: { cellWidth: "auto" },
                                },


                            ],
                            // ...
                        ],
                        columnStyles: {
                            0: { cellWidth: "auto" }, // Adjust column width for all columns
                            1: { cellWidth: "auto" }, // Adjust column width for all columns
                        },
                        headStyles: {
                            lineWidth: 0.5, // Border line for header
                        },
                        bodyStyles: {
                            lineWidth: 0.5, // Border line for body
                        },
                    });

                    let arr = [];

                    // Check if formData.clinicalInfo is defined before iterating over it
                    if (formData.clinicalInfo?.length) {
                        formData.clinicalInfo.forEach((items, index) => {
                            const design = {
                                content: items,
                                styles: { cellWidth: 5 },
                            };
                            arr.push(design);
                        });
                    } else {
                        // Handle the case where formData.clinicalInfo is undefined or an empty array
                        console.error(
                            "formData.clinicalInfo is undefined or empty."
                        );
                    }


                    const headerRow = "Clinical Indication";

                    autoTable(pdf, {
                        startY: 155,
                        head: [["Clinical Indication"]],
                        body: [

                            [

                                {
                                    content: `${formData.clinicalIndication || " "}`,
                                    styles: { cellWidth: "auto", cellPadding: 5 },
                                },
                            ],
                        ],
                        headStyles: {
                            lineWidth: 0.5, // Border line for header
                        },
                        bodyStyles: {
                            lineWidth: 0.5, // Border line for body
                        },
                    });

                    const arrContent2 = arr.reduce((result, item) => {
                        if (
                            item.content != "3T MRI" ||
                            item.content != "CT" ||
                            item.content != "X-ray" ||
                            item.content != "Ultrasound" ||
                            item.content != "Fluoroscopy" ||
                            item.content != "ECG" ||
                            item.content != "Echocardiogram" ||
                            item.content != "Exercise Stress" ||
                            item.content != "24-BP Monitor" ||
                            item.content != "Echocardiogram bubble study" ||
                            item.content != "Exercise stress echocardiogram" ||
                            item.content != "24-hour ECG Monitor" ||
                            item.content != "48-hour ECG Monitor" ||
                            item.content != "72-hour ECG Monitor" ||
                            item.content != "7-day ECG Monitor" ||
                            item.content != "14-day ECG Monitor"
                        ) {
                            result.push([item.content + ","]);
                        }
                        return result;
                    }, []);

                    // Remove duplicates and create an array of unique elements
                    const uniqueContent = Array.from(new Set(arrContent2));

                    // Split the unique content into four variables
                    const chunkSize = 3;
                    const variables = [];
                    const totalCells = uniqueContent.length;

                    for (let i = 0; i < totalCells; i += chunkSize) {
                        const variable = uniqueContent.slice(i, i + chunkSize);
                        variables.push([...variable]);
                    }

                    // If there are remaining cells, create an additional variable
                    const remainingCells = totalCells % chunkSize;
                    if (remainingCells > 0) {
                        const remainingVariable = uniqueContent.slice(totalCells - remainingCells);
                        variables.push([...remainingVariable]);
                    }

                    autoTable(pdf, {
                        startY: 113,
                        head: [["Diagnostic Tests", "", ""]],
                        body: [...variables],
                    });


                    autoTable(pdf, {
                        startY: 182,
                        head: [["Contrast investigations"]],
                        body: [
                            [
                                {
                                    content:
                                        "For patients above 65 years of age or with any known problems with kidney function, serum creatinine level or eGFR must be available prior to imaging.",
                                    styles: { cellWidth: "auto" },
                                },
                            ],
                            [
                                {
                                    content: `Serum creatinine/eGFR reading: ${formData.eGFR || " "}                                 Date taken: ${formattedDate}`,
                                    styles: { cellWidth: "auto" },
                                },
                            ],
                        ],
                        headStyles: {
                            lineWidth: 0.5, // Border line for header
                        },
                        bodyStyles: {
                            lineWidth: 0.5, // Border line for body
                        },
                    });

                    console.log("meta implement", formData.metalImplant);

                    autoTable(pdf, {
                        startY: 212,
                        head: [["Safety check"]],
                        body: [
                            [
                                {
                                    content: `Cardiac pacemakers, artificial heart valves, cochlear implants, cerebral aneurysm clips are contra-indicated for MRI`,
                                    styles: { cellWidth: "auto" },
                                },
                            ],
                            [
                                {
                                    content: `Does the patient have a metal implant or pacemaker? ${formData.metalImplant || " "
                                        }`,
                                    styles: { cellWidth: "auto" },
                                },
                            ],
                            [
                                {
                                    content: `Has the patient ever had an injury to the eye involving a metallic object? ${formData.eyeInjury || " "}`,
                                    styles: { cellWidth: "auto" },
                                },
                            ],
                        ],

                        headStyles: {
                            lineWidth: 0.5, // Border line for header
                        },
                        bodyStyles: {
                            lineWidth: 0.5, // Border line for body
                        },
                    });
                    autoTable(pdf, {
                        startY: 249,
                        head: [["Referring clinician’s details"]],
                        body: [
                            [
                                {
                                    content: `Referrer name: ${userData.name}                             Date: ${formattedDate}`,

                                    styles: { cellWidth: "auto" },
                                },
                            ],
                            [
                                {
                                    content: `GMC number: ${userData.registrationNumber}                                                    Contact tel no: ${userData.secretaryTelephone} `,
                                    styles: { cellWidth: "auto" },
                                },
                            ],

                        ],
                        headStyles: {
                            lineWidth: 0.5, // Border line for header
                        },
                        bodyStyles: {
                            lineWidth: 0.5, // Border line for body
                        },
                    });

                    const pdfBlob = pdf.output("blob");
                    let bigdata;

                    if (formData.title === "Prefer Not to Say") {
                        bigdata = {
                            ...formData,
                            /*                             consultant: id,
                             */
                            title: ""
                        };
                    } else if (formData.gender === "Prefer Not to Say") {
                        bigdata = {
                            ...formData,
                            consultant: counsultantId,
                            gender: "" // Empty string for gender
                        };
                    } else {
                        bigdata = {
                            ...formData,
                            consultant: counsultantId,
                        };
                    }

                    let data;

                    if (formData.title === "Prefer Not to Say") {
                        data = {
                            ...formData,
                            consultant: id,
                            title: ""
                        };
                    } else if (formData.gender === "Prefer Not to Say") {
                        data = {
                            ...formData,
                            consultant: id,
                            gender: ""
                        };
                    } else {
                        data = {
                            ...formData,
                            consultant: id,
                        };
                    }

                    console.log("dattatt",formData, data)

                    let response;
                    let response1;
                    if (location?.state) {
                        setLoading(true);
                        response = await axios.patch(
                            `patients/${patientId}`,
                            bigdata,
                            {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            }
                        );

                    } else {
                        setLoading(true);
                        response = await axios.post("patients/", data, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        });
                    }



                    if (response.status === 200 || response.status === 201) {
                        const id = response?.data?.data?._id;
                        toast(response?.data?.message);




                        if (action === "saveForLater") {
                            if (role === "consultant") {
                                window.location.reload();
                                window.location.href = "/all_patients";
                            } else {
/*                                 
 */                              setTimeout(() => {
                                navigate("/patients")
                                setLoading(false);
                            }, 500);
                            }

                        } else {
                            setTimeout(() => navigate("/refer_patient", { state: id }),);
                        }

                        setFormData({});
                    } else {
                        setLoading(false)
                        toast(response?.message ? response?.message : "Patient creation failed, please try again.");
                    }
                }
            } catch (error) {


                // Handle network errors or other unexpected issues
                console.error("Error submitting form:", error);
                toast(error?.response?.data?.message);
                setLoading(false)
            }
        }
    };
    useEffect(() => {
        const idFromState = location?.state;
        if (idFromState) {
            const fetchPatientData = async () => {
                try {
                    const response = await axios.get(`patients/${idFromState}`);
                    const patientData = response?.data?.data;
                    setCounsultantId(patientData.consultant);

                    setFormData(patientData);
                    setPatientId(idFromState);
                } catch (error) {
                    console.error("Error fetching patient data:", error);
                    toast.error("Failed to fetch patient data.");
                }
            };

            fetchPatientData();
        }
    }, [location?.state]);

    const dateFormat = () => {
        let date = new Date(formData.dob);
        let formattedDate = date.toISOString().slice(0, 10);
        console.log({ formattedDate });
        return formattedDate;
    };

    return (
        <>
            {loading ? (
                <>
                    <div className="p-36  flex justify-center items-center mt-20">
                        <Lottie animationData={animationData} />
                    </div>
                </>
            ) : (
                <>

                    <div className="container me-auto ms-5 w-4/5 my-5 py-5">
                        <ToastContainer />
                        {/* 
                <h2 className="text-2xl font-bold mb-4">
                    {formData.title &&
                        formData.title !== "Prefer Not to Say" &&
                        formData.firstName !== undefined &&
                        formData.lastName !== undefined
                        ? `${formData.title} ${formData.firstName} ${formData.lastName}`
                        : "Create New Patient"}
                </h2> */}
                        {/*  <h2 className="text-2xl font-bold mb-4">
                    {(formData.title && formData.title !== "Prefer Not to Say") ||
                        (!formData.title &&
                            formData.firstName !== undefined &&
                            formData.lastName !== undefined)
                        ? `${formData.title} ${formData.firstName} ${formData.lastName}`
                        : "Create New Patient"}
                </h2> */}
                        <h2 className="text-2xl font-bold mb-4">
                            {(formData.title === "Prefer Not to Say" &&
                                formData.firstName !== undefined &&
                                formData.lastName !== undefined)
                                ? `${formData.firstName} ${formData.lastName}`
                                : (formData.title && formData.title !== "Prefer Not to Say") &&
                                    formData.firstName !== undefined &&
                                    formData.lastName !== undefined
                                    ? `${formData.title} ${formData.firstName} ${formData.lastName}`
                                    : "Create New Patient"}
                        </h2>




                        <div className="grid grid-cols-2 gap-6 mt-5 pt-5">
                            <div className="mb-4">
                                <h2 className="text-xl font-[#344054] mb-4">
                                    Patient Details
                                </h2>
                                <label className="block text-sm font-medium text-gray-700">
                                    Title<span className="text-red-500">*</span>
                                </label>
                                <select
                                    name="title"
                                    value={formData.title || ""}
                                    onChange={handleInputChange}
                                    placeholder="Select Title"
                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"

                                >
                                    <option value="" disabled>
                                        Select Title
                                    </option>
                                    <option value="Mr">Mr</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Dr">Dr</option>
                                    <option value="Proof">Proof</option>
                                    <option value="Dame">Dame</option>
                                    <option value="Lady">Lady</option>
                                    <option value="Lord">Lord</option>
                                    <option value="Prefer Not to Say">
                                        Prefer Not to Say
                                    </option>
                                </select>
                                {errors.title && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.title}
                                    </p>
                                )}
                            </div>
                            <div className="mb-4">
                                <h2 className="text-xl font-[#344054] mb-4">
                                    Contact Information
                                </h2>
                                <label className="block text-sm font-medium text-gray-700">
                                    Email<span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="e.g. email@email.com"
                                    value={formData.email || ""}
                                    onChange={handleInputChange}
                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"

                                />
                                {errors.email && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.email}
                                    </p>
                                )}
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    First Name<span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter first name"
                                    value={formData.firstName || ""}
                                    onChange={handleInputChange}
                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                />
                                {errors.firstName && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.firstName}
                                    </p>
                                )}
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Phone Number<span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="number"
                                    name="phoneNumber"
                                    placeholder="e.g. 0737 138 0408"
                                    value={formData.phoneNumber || ""}
                                    onChange={handleInputChange}
                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                />
                                {errors.phoneNumber && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.phoneNumber}
                                    </p>
                                )}
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Last Name<span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName || ""}
                                    onChange={handleInputChange}
                                    placeholder="Enter last name"
                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                />
                                {errors.lastName && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.lastName}
                                    </p>
                                )}
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Postal Code
                                </label>
                                <input
                                    type="text"
                                    name="postalCode"
                                    value={formData.postalCode || ""}
                                    onChange={handleInputChange}
                                    placeholder="e.g. W1G 9QH"
                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                />
                                {errors.postalCode && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.postalCode}
                                    </p>
                                )}
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Date of Birth<span className="text-red-500">*</span>
                                </label>
                                <input
                                    type="date"
                                    name="dob"
                                    placeholder="DD/MM/YYYY"
                                    asp-format
                                    value={new Date(formData.dob || new Date()).toISOString().slice(0, 10)}
                                    onChange={handleInputChange}
                                    max={new Date().toISOString().split('T')[0]} // Set max attribute to today's date
                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                />

                                {/*  <input
                            type="date"
                            name="dob"
                            placeholder="DD/MM/YYYY"
                            asp-format
                            value={new Date(formData.dob || new Date())
                                .toISOString()
                                .slice(0, 10)}
                            onChange={handleInputChange}
                            className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                        /> */}
                                {errors.dob && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.dob}
                                    </p>
                                )}
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Town/City
                                </label>
                                <input
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    value={formData.city || ""}
                                    onChange={handleInputChange}
                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                />
                                {errors.city && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.city}
                                    </p>
                                )}
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Gender<span className="text-red-500">*</span>
                                </label>
                                <select
                                    name="gender"
                                    value={formData.gender || ""}
                                    onChange={handleInputChange}
                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                >
                                    <option value="" disabled>
                                        Select Gender
                                    </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    {/*  <option value="Prefer Not to Say">
                                Prefer Not to Say
                            </option> */}
                                    {(formData.gender === "Prefer Not to Say" || formData.gender === undefined) && (
                                        <option value="Prefer Not to Say">
                                            Prefer Not to Say
                                        </option>
                                    )}
                                </select>
                                {errors.gender && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.gender}
                                    </p>
                                )}
                            </div>


                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address || ""}
                                    onChange={handleInputChange}
                                    placeholder="Block/Street/Flat/Floor"
                                    className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                />
                                {errors.address && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.address}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <h2 className="ms-5 mb-4">Payment Information</h2>
                        <div className="flex justify-between items-center mx-5">
                            <div className="pb-6">
                                <label className="block text-sm font-medium text-gray-700">
                                    Payee<span className="text-red-500">*</span>
                                </label>
                                <select
                                    name="payment"
                                    value={formData.payment || ""}
                                    onChange={handleInputChange}
                                    className="mt-1 pe-2 p-2 border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                >
                                    <option value="" disabled>
                                        Select
                                    </option>

                                    <option value="Self-pay">Self-pay</option>
                                    <option value="Insurance/Embassy">
                                        Insurance / Embassy
                                    </option>
                                    <option value="Bill_Practice_Directly">Bill Practice Directly</option>
                                    <option value="Other">Other</option>
                                </select>
                                {errors.payment && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.payment}
                                    </p>
                                )}
                            </div>

                            {formData.payment === "Insurance/Embassy" && (
                                <div className="flex justify-evenly w-100 pl-4">
                                    <input
                                        type="text"
                                        name="provider"
                                        value={formData.provider || ""}
                                        onChange={handleInputChange}
                                        placeholder="Name of Provider"
                                        className="mt-1 me-2 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                    />
                                    <input
                                        type="text"
                                        name="policyNumber"
                                        value={formData.policyNumber || ""}
                                        onChange={handleInputChange}
                                        placeholder="Policy Number"
                                        className="mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                    />
                                    {errors.paymentMethod && (
                                        <p className="text-red-500 text-sm mt-1">
                                            {errors.paymentMethod}
                                        </p>
                                    )}
                                </div>
                            )}
                            {formData.payment === "Other" && (
                                <div className="flex justify-evenly w-100 pl-4">
                                    <input
                                        type="text"
                                        name="specify"
                                        value={formData.specify || ""}
                                        onChange={handleInputChange}
                                        placeholder="Specify"
                                        className="mt-1 me-2 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                    />

                                    {errors.paymentMethod && (
                                        <p className="text-red-500 text-sm mt-1">
                                            {errors.paymentMethod}
                                        </p>
                                    )}
                                </div>
                            )}
                            <div className="text-center sm:flex sm:justify-center">
                                <div className={`lg:justify-center ${role === "Admin" ? 'me-20' : '' // Add margin to the right for Admin role
                                    }`}
                                >
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSubmit(e, "saveForLater");
                                        }}
                                        className="flex px-4 py-2 bg-[#003942] text-white rounded-md mb-2 lg:w-[140px] lg:ms-3 lg:h-[43px] lg:justify-center"
                                    >
                                        Save Patient
                                        {/* {loading && (
                                    <Vortex
                                        visible={true}
                                        height="30"
                                        width="30"
                                        loading={loading}
                                        ariaLabel="vortex-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="vortex-wrapper"
                                        colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                                    />
                                )} */}
                                    </button>

                                </div>
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        // e.preventDefault();
                                        handleSubmit(e, "proceedToConfirm");
                                    }}
                                    className={`flex px-4 py-2 bg-[#003942] text-white rounded-md lg:w-[290px] lg:ms-3 lg:h-[43px] lg:justify-center ${role === "Admin" ? 'hidden' : ''}`}
                                >
                                    Proceed to Referral
                                    {/*  {loading && (
                                <Vortex
                                    visible={true}
                                    height="30"
                                    width="30"
                                    loading={loading}
                                    ariaLabel="vortex-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="vortex-wrapper"
                                    colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                                />
                            )} */}
                                </button>


                            </div>
                        </div>
                    </div>
                </>)}
        </>
    );
};

export default PatientForm;
