import React from 'react';

import { PiPhoneCallLight } from "react-icons/pi";
const Footer = () => {
  return (
    <>
     <p className='w-full bg-white text-center left-0 bottom-0' style={{zIndex:'-1 !important'}} > <p className='flex items-center justify-center gap-2'><PiPhoneCallLight className='ms-6' style={{width:'23px', height:'23px',  color: '#2E90FA'}} /> Need assistance? Call us on <a href='tel:07355094793' className='font-semibold text-black text-lg'>07355 094 793</a> or <a href='mailto:clientrelations@umegroup.com' className='font-semibold text-black text-lg'>clientrelations@umegroup.com </a> </p></p>
    </>
  );
}

export default Footer;
