import React, { useEffect } from "react";
import { IoCallOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Footer2 from "../components/Footer2";
import { toast } from "react-toastify";
import axios from "axios";

function WelcomeBanner() {
  const navigate = useNavigate();
  useEffect(() => {
    const id = JSON.parse(sessionStorage.getItem("data"))?.userData._id;
    console.log(id);
    axios
      .get(`/users/${id}`)
      .then((response) => {
        const userData = response.data?.data;
        console.log("response", userData.statusId);

        const name = userData.name;
        console.log(name);

        if (userData.statusId === "inactive") {
          navigate("/");
          setTimeout(() => {
            toast(
              `Dear ${name} your is in inactive. Please contact support for assistance.`
            );
          }, 500);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        // Handle error as needed
      });
  }, []);

  const handleRefer = () => {
    navigate("/all_patients");
  };
  return (
    <>
      <div
        className="text-center flex items-center flex-col justify-center rounded-sm overflow-hidden relative lg:h-1200"
        style={{
          height: "95.3vh",
          "@media (min-height: 1251px)": { height: "10vh" },
        }}
      >
        <div className="border shadow-2xl h-50 w-50 px-16 py-6 flex flex-col justify-center items-center rounded-lg">
          <h1 className="text-2xl md:text-3xl font-bold mb-1 text-[#003942]">
          Refer a Patient
          </h1>
          <p className="text-[black]">
            Make an online referral to our clinic.
          </p>
          <p>You can also book your patient in here.</p>
          <button
            onClick={() => handleRefer()}
            className="flex justify-center mx-auto rounded-xl px-4 py-2 my-3 overflow-hidden relative group cursor-pointer bg-[#003942] border-2 font-medium text-[16px] border-[#003942] w-4/5 text-sky-700 hover:scale-105 duration-[900ms]"
          >
            <span className="absolute w-full h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-50 bg-sky-700 top-2/5 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
            <span className="relative text-white transition duration-[900ms] group-hover:text-white ease">
              Submit New Referral
            </span>
          </button>
        </div>
        <div className="absolute bottom-3 w-full">
          <Footer2 />
        </div>
      </div>
    </>
  );
}

export default WelcomeBanner;
