import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { MdOutlineDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import axios from "../config/axios";
import { CiDatabase, CiUser } from "react-icons/ci";
import { FaEye } from "react-icons/fa";
import { IoDocumentOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Footer2 from "../components/Footer2";

const AllReferrals = () => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});


  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const user = JSON.parse(sessionStorage.getItem("data"))?.userData;
      const userId = user?._id;
      const response = await axios.get(`referrals/?consultant=${userId}`);
      const referrals = response?.data?.data;



      const matchedPatient = referrals.filter(referral => (
        referral.consultant._id === user._id && referral.rf === false
      ));
      matchedPatient.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      let new_data = matchedPatient.reduce((acc, curr) => {
        const uniqueId = curr.uniqueId;
        if (!acc[uniqueId]) {
          acc[uniqueId] = [];
        }
        acc[uniqueId].push(curr);
        return acc;
      }, {});

      // Object.keys(new_data).forEach(key => {
      //   new_data[key].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      // });
      console.log("new data", new_data)
      setData(new_data);


    } catch (error) {
      toast(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const id = JSON.parse(sessionStorage.getItem("data"))?.userData._id;

    axios.get(`/users/${id}`).then((response) => {
      const userData = response.data?.data;
      console.log("response", userData.statusId);

      const status = JSON.parse(sessionStorage.getItem("data"))?.userData
        .statusId;
      const name = userData.name;
      console.log(name);

      if (userData.statusId === "inactive") {
        navigate("/");
        setTimeout(() => {
          toast(
            `Dear ${name} your is in inactive. Please contact support for assistance.`
          );
        }, 500);
        return;
      }
    });
    fetchData();
  }, []);





  const handleEdit = (id) => {
    console.log(id);
    navigate("/refer_patient", {
      state: id,
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`referrals/${id}`);
      if (response.status === 200 || response.status === 204) {
        setData((prevData) =>
          prevData.filter((patient) => patient?._id !== id)
        );
        toast.success("Referral deleted successfully.");
      } else {
        toast(response?.data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Failed to delete patient"
      );
    }
  };
  
  const handleCreateReferral = (id) => {
    console.log(`Create a referral for patient with ID ${id}`);
  };
  const handleCreatePatient = () => {
    navigate("/patient_list");
  };

  const handleRequestUpdate = async (id, patientName) => {
    try {
      const response = await axios.post("/referrals/update", {
        id,
        patientName,
      });
      toast.success(response.data.message);
    } catch (error) {
      const errorMessage = error.response.data.message;
      toast.error(errorMessage);

    }
  };
  return (
    <>
      {
        window.innerWidth < 500 ? (
          <div className="text-center text-white p-4 bg-red-500">
            Sorry! this portal is only operatable through desktop devices.
          </div>
        ) : (
          <div className="px-4 relative" style={{ height: "93vh", backgroundColor: "white" }}>
            <ToastContainer />
            <button
              onClick={handleCreatePatient}
              className="text-base border flex ms-auto me-3 p-2 rounded-lg px-3 my-3"
              style={{ background: "#003942", color: "white" }}
            >
              <GoPlus className="my-auto" style={{ fontSize: "25px" }} />{" "}
              Create New Patient
            </button>
            <h2 className="mx-auto text-center text-3xl">All Referrals</h2>
            <div className="w-4/5 my-3 mx-auto" style={{ width: "60%" }}>
              <p className="text-sm text-center" >
                Here you can see a list of your recent referrals. Click
                ‘View’ to see the referral in full. If you cannot see the
                referral you are looking for straight away, try clicking on
                the column headers to reorder the list by date and patient name.

              </p>
            </div>
            <div className="flex justify-center text-center mx-8 flex-col gap-2">
              <table className="min-w-full border border-gray-100 mb-[50px]">
                <thead>
                  <tr className="bg-[#EAF6F9]">
                    <th className="py-2 px-4 border border-[#D0D5DD]">
                      Patient Name
                    </th>
                    <th className="py-2 px-4 border border-[#D0D5DD]">
                      Modality
                    </th>
                    <th className="py-2 px-4 border border-[#D0D5DD]">
                      Created Date
                    </th>
                    <th className="py-2 px-4 border border-[#D0D5DD] text-center">
                      View
                    </th>

                    <th className="py-2 px-4 border border-[#D0D5DD] text-center">
                      UME Health Report
                    </th>

                    <th className="py-2 px-4 border border-[#D0D5DD] text-center">
                      Status
                    </th>
                    <th className="py-2 px-4 border border-[#D0D5DD] text-center">
                      Request Update From UME
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data).map((uniqueId, groupIndex) => (
                    <>
                    {data[uniqueId].map((referral, index) => (


                      referral?.pdfURL && (
                      <tr key={referral._id} className={`unique-id-${uniqueId}`}
                        style={{
                          marginBottom: index === 0 ? '4px' : '0px',
                          borderLeft: '5px solid #EAF6F9',
                          borderRight: '5px solid #EAF6F9',
                          borderTop: index === 0 ? '5px solid #EAF6F9' : 'none',
                          borderBottom: index === referral?.pdfURL.length - 1 ? '5px solid #EAF6F9' : 'none',
                        }}>
                        <td className="border border-[#D0D5DD] text-left gap-2 mx-auto h-auto">
                          {referral?.title !== "Prefer Not to Say" && (
                            <>
                              {referral?.title + " "}
                            </>
                          )}
                          {referral?.firstName + " " + referral?.lastName}
                        </td>
                        <td className="border border-[#D0D5DD] w-[150px]">
                          {referral?.clinicalInfo?.[0]}
                        </td>

                        <td className="border border-[#D0D5DD] w-[150px]">
                          {new Date(referral.createdAt).toLocaleDateString()}
                        </td>
                        <td className="border border-[#D0D5DD]">
                          <a
                            href={referral?.pdfURL ? referral?.pdfURL : "#"}
                            download={referral?.pdfURL ? true : false}
                            target="_blank"
                          >
                            <FaEye className="text-xl mx-auto text-[#2CA1C8] hover:shake" />
                          </a>
                        </td>

                        <td className="border border-[#D0D5DD]">
                          <div
                            style={{ display: 'flex' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!referral?.finalReport) {
                                // Handle any other click logic specific to the icon
                              }
                            }}
                          >
                            {referral?.finalReport1 && (
                              <a href={referral?.finalReport1} target="_blank" className="text-xl mx-auto">
                                <IoDocumentOutline
                                  className={`text-xl mx-auto text-[#2E90FA] hover:shake`}
                                  style={{
                                    color: referral?.finalReport ? "#2CA1CB" : "#2E90FA",
                                    cursor: referral?.pending ? "not-allowed" : "pointer",
                                  }}
                                />
                              </a>
                            )}
                            {referral?.finalReport2 && (
                              < a href={referral?.finalReport2} target="_blank" className="text-xl mx-auto">
                                <IoDocumentOutline
                                  className={`text-xl mx-auto text-[#2E90FA] hover:shake`}
                                  style={{
                                    color: referral?.finalReport ? "#2CA1CB" : "#2E90FA",
                                    cursor: referral?.pending ? "not-allowed" : "pointer",
                                  }}
                                />
                              </a>


                            )}
                            {!(referral?.finalReport1) || !(referral?.finalReport2) ?

                              < IoDocumentOutline
                                className="text-xl mx-auto text-[grey]"
                                style={{ color: "grey" }}
                              /> : null
                            }
                          </div>
                        </td>




                        < td className="px-4 border border-[#D0D5DD] text-center  text-base p-2 text-center items-center justify-center" >
                          <p className="p-2 border rounded-lg border-[#003942] whitespace-nowrap">
                            {referral?.pending === true
                              ? "Awaiting UME Report"
                              : "Completed"}
                          </p>
                        </td>
                        <td
                          className="border-[#D0D5DD] text-center text-base p-2 cursor-pointer text-center items-center justify-center ms-auto"
                          onClick={() =>
                            handleRequestUpdate(
                              referral?._id,
                              referral?.firstName + " " + referral?.lastName
                            )
                          }
                        >
                          <button
                            className={`p-2 border rounded-lg hover:shake ${referral?.pending
                              ? "bg-[#003942] text-white"
                              : "bg-[#D0D5DD] text-white cursor-not-allowed"
                              }`}
                            disabled={!referral?.pending}
                          >
                            {referral?.pending
                              ? "Request Update"
                              : "Request Update "}
                          </button>
                        </td>
                      </tr>
                      )

                      ))}
                      {groupIndex < Object.keys(data).length - 1 && (
                        <tr key={`spacer-${uniqueId}`} style={{ height: '20px' }}></tr>
                      )}
                    </>
                  ))}
                </tbody>

              </table>

            </div >

          </div >
        )}</>
  );
};

export default AllReferrals;
