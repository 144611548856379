import jsPDF from "jspdf";
import Logo from "../Assets/images/logomin.png";
import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import { formatDate, formatName } from "./seed"
import axios from "../config/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const generatePdf = (arr, userData, formData) => {
    const pdf = new jsPDF({
        compression: true
    });
    const logo = new Image();
    logo.src = Logo;
    pdf.addImage(logo, 10, 9, 80, 14);
    pdf.setFontSize(16);
    pdf.setFont("bold");
    pdf.setFontSize(11);
    pdf.setFont("bold");
    pdf.text("Telephone:", 120, 16);
    pdf.text("0207 467 6190", 138, 16);
    pdf.text("Email:", 120, 23);
    pdf.text("bookings@umegroup.com", 131, 23);
    pdf.text("17 Harley Street, London W1G 9QH", 120, 30);
    pdf.setFontSize(28);
    pdf.setFont("bold");
    pdf.text("Imaging Referral Form", 65, 44);
    const contentValue = formData?.payment === "Other"
        ? `Specify: ${formData?.specify || " "}`
        : `Insurance company: ${formData?.provider || " "}`


    autoTable(pdf, {
        startY: 48,
        head: [
            ["Patient details", `Date of referral: ${formatDate(Date.now())}`],
        ],
        body: [
            [
                {
                    content: `Title: ${formData.title}`,
                    styles: { cellWidth: "auto" },
                },
                {
                    content: `Address: ${formData.address}`,
                    styles: { cellWidth: "auto" },
                },
            ],
            [
                {
                    content: `First name: ${formData.firstName}`,
                    styles: { cellWidth: "auto" },
                },

                {
                    content: `Postcode: ${formData.postalCode}`,
                    styles: { cellWidth: "auto" },
                },
            ],

            [
                {
                    content: `Last name: ${formData.lastName}`,
                    styles: { cellWidth: "auto" },
                },
                {
                    content: `Telephone: ${formData.phoneNumber}`,
                    styles: { cellWidth: "auto" },
                },
            ],

            [
                {
                    content: `DOB: ${formatDate(formData.dob)}`,
                    styles: { cellWidth: "auto" },
                },
                {
                    content: `Email: ${formData.email}`,
                    styles: { cellWidth: "auto" },
                },
            ],
            [

                {
                    content: `Gender: ${formData.gender !== "Prefer Not to Say" ? formData.gender : ""}`,
                    styles: { cellWidth: "auto" },
                },
                {
                    content: `Payee: ${formatName(formData?.payment)}`,
                    styles: { cellWidth: "auto" },
                },
            ],

            [
                {
                    content: `Mobility: ${formData.mobility || " "}`,
                    styles: { cellWidth: "auto" },
                },


                {
                    content: contentValue,
                    styles: { cellWidth: "auto" },
                },



            ],

            [
                {
                    content: `Pregnancy: ${formData.pregnancyStatus || " "}`,
                    styles: { cellWidth: "auto" },
                },
                {
                    content: `Policy number: ${formData.policyNumber || " "}`,
                    styles: { cellWidth: "auto" },
                },


            ],

        ],
        columnStyles: {
            0: { cellWidth: "auto" }, // Adjust column width for all columns
            1: { cellWidth: "auto" }, // Adjust column width for all columns
        },
        headStyles: {
            lineWidth: 0.5, // Border line for header
        },
        bodyStyles: {
            lineWidth: 0.5, // Border line for body
        },
    });

    const arrContent2 = arr.reduce((result, item) => {
        if (
            item.content != "3T MRI" ||
            item.content != "CT" ||
            item.content != "X-ray" ||
            item.content != "Ultrasound" ||
            item.content != "Fluoroscopy" ||
            item.content != "ECG" ||
            item.content != "Echocardiogram" ||
            item.content != "Exercise Stress" ||
            item.content != "24-BP Monitor" ||
            item.content != "Echocardiogram bubble study" ||
            item.content != "Exercise stress echocardiogram" ||
            item.content != "24-hour ECG Monitor" ||
            item.content != "48-hour ECG Monitor" ||
            item.content != "72-hour ECG Monitor" ||
            item.content != "7-day ECG Monitor" ||
            item.content != "14-day ECG Monitor"
        ) {
            result.push([item.content]);
        }
        return result;
    }, []);


    const uniqueContent = Array.from(new Set(arrContent2));


    const chunkSize = 1;
    const variables = [];
    const totalCells = uniqueContent.length;

    for (let i = 0; i < totalCells; i += chunkSize) {
        const variable = uniqueContent.slice(i, i + chunkSize);
        variables.push([...variable]);
    }

    let diagnosticTestsContent = 0;
    variables.forEach(row => {
        row.forEach(cell => {
            diagnosticTestsContent += 1
        });
    });




    autoTable(pdf, {
        head: [["Diagnostic tests"]],
        body: variables.map(row => {
            row.forEach((cell, index) => {

                row[index] = { content: `• ${cell}`, styles: { fontSize: 10 } };
            });

            return row;
        }),
        headStyles: {
            lineWidth: 0.5,
        },
        bodyStyles: {
            lineWidth: 0.5,
        },

    });

    autoTable(pdf, {

        head: [["Clinical indication"]],
        body: [

            [

                {
                    content: `${formData.clinicalIndication || " "}`,
                    styles: { cellWidth: "auto", cellPadding: 5 },
                }
            ],
        ],
        headStyles: {
            lineWidth: 0.5,
        },
        bodyStyles: {
            lineWidth: 0.5,
        },
    });


    autoTable(pdf, {
        // startY: 167,
        head: [["Contrast investigations"]],
        body: [
            [
                {
                    content:
                        "For patients above 65 years of age or with any known problems with kidney function, serum creatinine level or eGFR must be available prior to imaging.",
                    styles: { cellWidth: "auto" },
                },
            ],
            [
                {
                    content: `Serum creatinine / eGFR reading: ${formData.eGFR || " "}                            Date taken: ${formData.date ? formatDate(formData.date) : " "}`,



                    styles: { cellWidth: "auto" },
                },
            ],
        ],
        headStyles: {
            lineWidth: 0.5, // Border line for header
        },
        bodyStyles: {
            lineWidth: 0.5, // Border line for body
        },
    });



    autoTable(pdf, {
        // startY: 197,
        head: [["Safety check"]],
        body: [
            [
                {
                    content: `Cardiac pacemakers, artificial heart valves, cochlear implants, cerebral aneurysm clips are contra-indicated for MRI`,
                    styles: { cellWidth: "auto" },
                },
            ],
            [
                {
                    content: `Does the patient have a metal implant or pacemaker ? ${formData.metalImplant || " "
                        } `,
                    styles: { cellWidth: "auto" },
                },
            ],
            [
                {
                    content: `Has the patient ever had an injury to the eye involving a metallic object ? ${formData.eyeInjury || " "} `,
                    styles: { cellWidth: "auto" },
                },
            ],
        ],

        headStyles: {
            lineWidth: 0.5, // Border line for header
        },
        bodyStyles: {
            lineWidth: 0.5, // Border line for body
        },
    });
    autoTable(pdf, {
        head: [["Referring clinician’s details", ""]],
        body: [
            [
                {
                    content: `Referrer name: ${userData.name}`,
                    styles: { cellWidth: "auto" },
                },
                {
                    content: `Date:  ${formatDate(Date.now())}`,
                    styles: { cellWidth: "auto" },
                },
            ],



            [
                {
                    content: `GMC number: ${userData.registrationNumber}`,
                    styles: { cellWidth: "auto" },
                },
                {
                    content: `Secretary telephone: ${userData.secretaryTelephone} `,
                    styles: { cellWidth: "auto" },
                },
            ],

        ],
        headStyles: {
            lineWidth: 0.5, // Border line for header
        },
        bodyStyles: {
            lineWidth: 0.5, // Border line for body
        },
    });
    const pdfBlob = pdf.output("blob");
    const timestamp = Date.now();
    const fileName = `patient_form_${timestamp}.pdf`;
    const pdfFile = new File([pdfBlob], fileName);
    return pdfFile

}



export const getUser = () => {
    const id = JSON.parse(sessionStorage.getItem("data"))?.userData._id;
    axios.get(`/users/${id}`).then((response) => {
        const userData = response.data?.data;
        const status = JSON.parse(sessionStorage.getItem("data"))?.userData
            .statusId;
        const name = userData.name;
        if (userData.statusId === "inactive") {
            window.location.assign('/');
            setTimeout(() => {
                toast(
                    `Dear ${name} your is in inactive. Please contact support for assistance.`
                );
            }, 500);
            return;
        }
    });
}

export function convertString(input) {
    // Split the string by '>'
    let parts = input.split('>');

    // Trim each part and take only the first two parts
    let trimmedParts = parts.slice(0, 2).map(part => part.trim());

    // Join the first two parts with a hyphen
    let result = trimmedParts.join(' - ');

    return result;
}
export const generatePdfFiles = async (checkboxes, userData, formData, formDatas) => {
    const arr = [];
    const pdfs = [];
    const clinicalInfo = [];
    let pdfFile;

    const checkedData = checkboxes?.filter(data => data.checked === true);

    for (const data of checkedData) {

        if (data?.pairs) {
            console.log("pair",data?.pairs)
            const filteredPairs = data.pairs.filter(pair => pair?.value1 && pair?.value2);
            const designs = filteredPairs.map(pair => ({
                content: `${data.name}  >  ${pair.value1}  >  ${pair.value2}`,
                styles: { cellWidth: 5 },
            }));

            const generatedPdfFiles = await Promise.all(designs.map(design => generatePdf([design], userData, formData)));
            generatedPdfFiles.forEach((pdfFile, index) => {
                arr.push(designs[index]);
                pdfs.push(pdfFile);
                formDatas.append("pdfs", pdfFile, `file${pdfs.length}.pdf`);
                let output = convertString(designs[index]?.content);
                clinicalInfo.push(output);
                console.log("cccpair",clinicalInfo,pdfs)
            });
        }
        else {
            const design = {
                content: data.name,
                styles: { cellWidth: 5 },
            };
            arr.push(design);
            pdfFile = await generatePdf([design], userData, formData);
            pdfs.push(pdfFile);
            formDatas.append("pdfs", pdfFile, `file${pdfs.length}.pdf`);
            clinicalInfo.push(data.name);
        }
    }

    return { arr, pdfs, clinicalInfo };
};
