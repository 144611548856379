import React from 'react';

import { PiPhoneCallLight } from "react-icons/pi";
const Footer = () => {
  return (
    <>
     <p className='w-full bg-white text-center absolute left-0 bottom-0 flex items-center justify-center gap-2' > <PiPhoneCallLight className='' style={{width:'23px', height:'23px',  color: '#2E90FA'}} /> Need assistance? Call us on <a href='tel:07355094793' target="_blank">07355 094 793</a> or <a href='mailto:clientrelations@umegroup.com' target="_blank" className=''>clientrelations@umegroup.com </a> </p>
    </>
  );
}

export default Footer;
