import axios from "axios";
axios.defaults.baseURL = "https://ume-health-referral-backend-main-rho.vercel.app/v1/";

// axios.defaults.baseURL = "http://localhost:4001/v1/";
axios.defaults.withCredentials = true;


if (sessionStorage.getItem("data")) {
  const data = JSON.parse(sessionStorage.getItem("data"));
  axios.defaults.headers.common = { Authorization: `bearer ${data.token}` };
}

export default axios;
