// Dashboard.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Header from '../partials/Header';
import WelcomeBanner from '../partials/WelcomeBanner';
import SidebarMenu from '../components/Sidebar';
import MultiStepForm from './MultipstepForm';
import PatientForm from './Patient';
import AllPatient from './AllPatient';
import AllReferrals from './AllReferal';
import Profile from './Profile';
import Referrals from './admin/Referals';
import Consultants from './admin/consultants';
import Patients from './admin/Patients';

function Dashboard() {
  return (
    <>
      {
        window.innerWidth < 500 ? (
          <div className="text-center text-white p-4 bg-red-500">
            Sorry! this portal is only operatable through desktop devices.
          </div>
        ) : (
          <div className="flex h-screen overflow-hidden bg-[white]">
            <SidebarMenu />
            {/* Content area */}
            <div className="flex flex-col flex-1 overflow-x-hidden">
              {/* Site header */}
              <Header />

              <main>
                <Routes>
                  <Route path="welcome" index element={<WelcomeBanner />} />
                  <Route path="patient_list" element={<PatientForm />} />
                  <Route path="refer_patient" element={<MultiStepForm />} />
                  <Route path="all_patients" element={<AllPatient />} />
                  <Route path="all_referrals" element={<AllReferrals />} />
                  <Route path="referals" element={<Referrals />} />
                  <Route path="consultants" element={<Consultants />} />
                  <Route path="patients" element={<Patients />} />
                  <Route path="profile" element={<Profile />} />
                </Routes>
              </main>
            </div>
          </div>
        )}</>
  );
}

export default Dashboard;
