import React from 'react';

import UserMenu from '../components/DropdownProfile';

function Header() {

  return (
    <header className="sticky top-0  bg-[#F9FAFB] border-b border-slate-200 dark:border-slate-700 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-right -mb-px" style={{height : '51px', justifyContent:'right'}}>

          {/* Header: Right side */}
          <div className="flex items-right space-x-3">
            <div>
            <UserMenu align="right"  />
          </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
