import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../config/axios";
import { FiEdit } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';


const Patients = () => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get("patients/");
            const patients = response?.data?.data;

           
            const uniqueNames = new Set();

            
            const uniquePatients = patients.filter((patient) => {
                const fullName = `${patient?.title} ${patient?.firstName} ${patient?.lastName}`.trim();
                if (!uniqueNames.has(fullName)) {
                    uniqueNames.add(fullName);

                    return true;
                }
                return false;
            });
           
            setData(uniquePatients);
        } catch (error) {
            toast(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
   
    const handleEdit = (id) => {
        navigate("/patient_list", {
            state: id,

        });
    }
    const navigate = useNavigate();

    const columns = [
        {
            name: 'Patient Name',
            selector: 'patientName',
            sortable: true,
            center: true,
            width: '220px',
            cell: (row) => (
                <>
                    {`${row?.title} ${row?.firstName} ${row?.lastName}`.length > 14
                        ? `${row?.title} ${row?.firstName} ${row?.lastName}`
                        : `${row?.title} ${row?.firstName} ${row?.lastName}`}
                </>
            ),

        },
        {
            name: "Created by",
            selector: 'name',
            center: true,

            sortable: true,
            cell: (row) => (
                <>

                    {/*  {row?.consultant?.name} */}
                    {`${row?.consultant?.name}`.length > 14
                        ? `${row?.consultant?.name}`
                        : `${row?.consultant?.name}`}
                </>
            ),
        },
        {
            name: 'Created Date',
            selector: 'createdDate',
            center: true,
            width: '220px',
            sortable: true,
            cell: (row) => (
                <span className="text-sm">
                    {new Date(row?.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                    })}
                </span>
            ),
        },
        {
            name: 'Edit',
            selector: 'edit',
            center: true,
            width: '170px',
            sortable: false,
            cell: (row) => (
                <FiEdit
                    className="text-[#2CA1C8] text-xl"
                    onClick={() => handleEdit(row._id)}
                />
            ),
        },
    ];



    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`patients/${id}`);
            if (response.status === 200 || response.status === 204) {
                setData((prevData) =>
                    prevData.filter((patient) => patient?._id !== id)
                );
                toast.success("Patient deleted successfully.");
            } else {
                console.log("error===>", response?.data?.message);
                toast(response?.data?.message);
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message || "Failed to delete patient"
            );
        }
    };

    const handleCreateReferral = (id) => {
        const patient = data?.filter((patient) => patient?._id === id);
        if (patient[0]?.clinicalInfo.length === 0) {
            navigate("/refer_patient", {
                state: id,
            });
        } else {
            navigate("/all_referrals");
        }
    };

    return (

        <>
            {
                window.innerWidth < 500 ? (
                    <div className="text-center text-white p-4 bg-red-500">
                        Sorry! this portal is only operatable through desktop devices.
                    </div>
                ) : (
                    <div className='container w-[77%] mx-auto' >
                        <ToastContainer />
                        <h1 className='text-center text-3xl font-semibold mt-5 mb-5'>All Patients</h1>

                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            highlightOnHover
                            responsive
                            customStyles={{
                                headRow: {
                                    style: {
                                        borderTop: '1px solid #dee2e6', // Border color for the top of the header row
                                        backgroundColor: '#E5F4FF',
                                    },
                                },
                                headCells: {
                                    style: {
                                        borderRight: '1px solid #dee2e6', // Border color for the right of each header cell
                                    },
                                },
                                rows: {
                                    style: {
                                        borderBottom: '1px solid #dee2e6', // Border color for the bottom of each row
                                        backgroundColor: 'gray-200',
                                    },
                                },
                                cells: {
                                    style: {
                                        borderRight: '1px solid #dee2e6',
                                        borderLeft: "1px solid #dee2e6",
                                    },
                                },
                            }}
                        />
                    </div>
                )} </>
    );
};

export default Patients;
