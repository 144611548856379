import axios from "../../config/axios";
import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import './consultant.css'

const Consultants = () => {
    const [consultants, setConsultants] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [consultantsPerPage] = useState(6);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const fetchData = async () => {
        const response = await axios.get("users/");
        let resData = response?.data?.data?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
        setConsultants(resData);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const indexOfLastConsultant = currentPage * consultantsPerPage;
    const indexOfFirstConsultant = indexOfLastConsultant - consultantsPerPage;
    const currentConsultants = consultants.filter(consultant =>
        consultant.name.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(
        indexOfFirstConsultant,
        indexOfLastConsultant
    );

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleCreatePatient = () => {
        navigate('/signup');
    };

    return (
        <>

            {
                window.innerWidth < 500 ? (
                    <div className="text-center text-white p-4 bg-red-500">
                        Sorry! this portal is only operatable through desktop devices.
                    </div>
                ) : (

                    <div className="container w-11/12 mx-auto ml-12 mr-5">
                        <h1 className="text-center text-3xl font-semibold mt-5 mb-2">
                            Registered Consultants
                        </h1>
                        <input
                            type="text"
                            placeholder="Search by name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="text-base border flex ms-auto me-3 p-2 rounded-lg px-3 my-3"

                        />

                        {currentConsultants.map((consultant, index) => (
                            <div
                                key={index}
                                className="flex p-4 my-4 gap-6 hover:scale-105 transition duration-300"
                                style={{ borderRadius: "10px", border: '1px solid rgb(0,57,66)' }}
                            >
                                <div className="flex-1">

                                    <p className="text-lg font-semibold">
                                        {consultant?.name}
                                    </p>
                                    <p>{consultant?.email}</p>

                                    <p>
                                        {" "}
                                        Registered on :{" "}
                                        {new Date(
                                            consultant?.createdAt
                                        ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        })}
                                    </p>
                                </div>
                                <div>
                                    <div>
                                        <FiEdit
                                            style={{
                                                color: "#2CA1C8",
                                                cursor: "pointer",
                                                marginLeft: "10px"
                                            }}
                                            onClick={() =>
                                                navigate("/profile", {
                                                    state: { consultant },
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="text-sm font-bold mt-3 mr-2">
                                        <span className="text-md font-bold text-[red]">
                                            {consultant?.statusId === "active" || consultant?.statusId == null ? "" : "Blocked"}
                                        </span>
                                    </div>

                                </div>

                            </div>

                        ))}
                        
                        <div
                            className="pagination-container mx-auto flex items-center justify-center"
                            style={{
                                display: "flex",
                                flexDirection: "column", 
                                alignItems: "center", 
                                marginTop: "20px",
                            }}
                        >

                            <ul
                                className="pagination"
                                style={{
                                    listStyle: "none",
                                    display: "flex",
                                    gap: "10px",
                                    marginBottom: "10px", 
                                }}
                            >
                                {Array.from(
                                    {
                                        length: Math.ceil(consultants.length / consultantsPerPage),
                                    },
                                    (_, index) => (
                                        <li key={index}>
                                            <button className="hover:transform-scale-110 transition-transform duration-300 mb-[20px]"
                                                onClick={() => paginate(index + 1)}
                                                style={{
                                                    padding: "5px 10px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#003942",
                                                    color: "white", // Set text color to white
                                                    cursor: "pointer",
                                                    fontWeight: "600", // Set to semi-bold
                                                    fontSize: "14px", // Adjust font size as needed
                                                }}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>


                    </div>)} </>
    );
};

export default Consultants;
