import React, { useState, useRef } from "react";
import axios from "axios";
import { FaRegImage } from "react-icons/fa";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
const defaultImage = "https://res.cloudinary.com/dhvzkuuft/image/upload/v1714492571/images/pilu1r4yajrokluntuup_z7oajl.jpg"

const Profile = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [id, setId] = useState("");
    const [showPass, setShowPass] = useState("");

    const [role, setRole] = useState("");
    const [block, setBlock] = useState(false);
    const [pd, setPd] = useState("")
    const [imageChange, setImageChange] = useState(false);
    const fileInputRef = useRef(null);
    const [inputs, setInputs] = useState([""]);



    const [formData, setFormData] = useState({
        title: "",
        firstname: "",
        lastname: "",
        name: "",
        practitionerSpecialty: "",
        practitionerGroup: "",
        primaryPracticeName: "",
        registrationNumber: "",
        street: "",
        jobTitle: "",
        postcode: "",
        landline: "",
        email: "",
        townCity: "",
        password: "",
        statusId: "",
        secretaryName: "",
        secretaryTelephone: "",
        secretaryEmail: "",
        image: defaultImage,
        preferred_radiologists: "",
        preferred_radiologists_list: []
    });

    const addInput = () => {
        setInputs([...inputs, '']);
    };

    const removeInput = (index) => {
        const updatedInputs = [...inputs];
        updatedInputs.splice(index, 1);
        setInputs(updatedInputs);
        setFormData(prevData => ({
            ...prevData,
            preferred_radiologists_list: updatedInputs
        }));
    };

    const handleInputChanges = (index, value) => {
        const updatedInputs = [...inputs];
        updatedInputs[index] = value;
        setInputs(updatedInputs);
        setFormData(prevData => ({
            ...prevData,
            preferred_radiologists_list: updatedInputs
        }));
    };

    useEffect(() => {
       
        if (state) {
            if (state?.consultant.statusId === "inactive") {
                setBlock(true)
            }
            setFormData({ ...state?.consultant });

            if(state?.consultant?.preferred_radiologists_list?.length){
                setInputs(state?.consultant?.preferred_radiologists_list)
            }else{
                setInputs([""])
            }
           
            setId(state?.consultant?._id);

        } else {

            const userId = JSON.parse(sessionStorage.getItem("data"))?.userData
                ?._id;
            const role = JSON.parse(sessionStorage.getItem("data"))?.userData
                ?.role;
            setRole(role);
            setId(userId);

            if (userId) {
                axios
                    .get(`/users/${userId}`)
                    .then((response) => {
                        const userData = response.data?.data;
                        setFormData({ ...userData, password: `${pd}` });
                        if(userData?.preferred_radiologists_list?.length){
                            setInputs(userData?.preferred_radiologists_list)
                        }else{
                            setInputs([""])
                        }


                        const status = JSON.parse(sessionStorage.getItem("data"))?.userData.statusId;
                        const name = userData.name;
                        if (userData.statusId === "inactive") {
                            navigate("/");
                            setTimeout(() => {
                                toast(
                                    `Dear ${name} your is in inactive. Please contact support for assistance.`
                                );
                            }, 500);
                            return;
                        }

                    })
                    .catch((error) => {
                        console.error("Error fetching user data:", error);
                    });
            }
        }
        console.log(inputs);
        return () => {
            setImageChange(false);
        };
    }, []);



    const toogler = () => {
        setBlock((prevBlock) => {
            setFormData((prevData) => ({
                ...prevData,
                statusId: !prevBlock ? "inactive" : "active",
            }));
            return !prevBlock;
        });
        if (formData.statusId == "inactive") {
            toast(`Please confirm the unblocking of ${formData.title} ${formData.firstname} ${formData.lastname} by clicking the "Save" button.`)
        } else {
            toast(`Please confirm the blocking of ${formData.title} ${formData.firstname} ${formData.lastname} by clicking the "Save" button.`)
        }

    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name == "Allow_UME_to_allocate_for_me") {
            setInputs([""])
            setFormData(prevData => ({
                ...prevData,
                preferred_radiologists_list: [""]
            }));
        }
    };

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        setFormData({
            ...formData,
            image: selectedImage,
        });
        setImageChange(true);
    };
    const handleRemoveImage = () => {
        setFormData({
            ...formData,
            image: null,
        });
        setImageChange(false);
    };
    const handleClickImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


    const handleSubmit = async () => {
        const role = JSON.parse(sessionStorage.getItem("data"))?.userData
            ?.role;

        if (role === "consultant") {
            if (formData?.password === "" || formData?.password === undefined) {
                toast("Please fill out all required fields to continue.");
                return true;
            } else if (formData.password.length < 8) {
                toast("Password should not be less than 8 characters.");
                return true;
            }
        }



        if (formData?.title === "" || formData?.title === undefined) {
            toast("Please fill out all required fields to continue.");
            return true;
        }
        if (formData?.firstname === "" || formData?.firstname === undefined) {
            toast("Please fill out all required fields to continue.");
            return true;
        }
        if (formData?.jobTitle === "" || formData?.jobTitle === undefined) {
            toast("Please fill out all required fields to continue.");
            return true;
        }
        if (formData?.lastname === "" || formData?.lastname === undefined) {
            toast("Please fill out all required fields to continue.");
            return true;
        }
        if (formData?.email === "" || formData?.email === undefined) {
            toast("Please fill out all required fields to continue.");
            return true;
        }
        if (formData?.registrationNumber === "" || formData?.registrationNumber === undefined) {
            toast("Please fill out all required fields to continue.");
            return true;
        }
        if (formData?.secretaryEmail === "" || formData?.secretaryEmail === undefined) {
            toast("Please fill out all required fields to continue.");
            return true;
        }
        if (formData?.secretaryTelephone === "" || formData?.secretaryTelephone === undefined) {
            toast("Please fill out all required fields to continue.");
            return true;
        }
        if (formData?.secretaryName === "" || formData?.secretaryName === undefined) {
            toast("Please fill out all required fields to continue.");
            return true;
        } else {
            let formData1;
            formData1 = {
                title: formData.title,
                firstname: formData.firstname,
                lastname: formData.lastname,
                name: formData.name,
                practitionerSpecialty: formData.practitionerSpecialty,
                practitionerGroup: formData.practitionerGroup,
                primaryPracticeName: formData.primaryPracticeName,
                registrationNumber: formData.registrationNumber,
                street: formData.street,
                jobTitle: formData.jobTitle,
                postcode: formData.postcode,
                landline: formData.landline,
                // password: formData.password,
                email: formData.email,
                townCity: formData.townCity,
                statusId: formData.statusId,
                secretaryName: formData.secretaryName,
                secretaryTelephone: formData.secretaryTelephone,
                secretaryEmail: formData.secretaryEmail,
                image: formData.image,
                check: true,
                preferred_radiologists: formData.preferred_radiologists,
                preferred_radiologists_list: inputs
            }



            let Data;

            if (formData.title === "Prefer Not to Say") {
                Data = {
                    ...formData,
                    name: `${formData.firstname} ${formData.lastname}`,
                    check: false
                };
            } else {
                Data = {
                    ...formData,
                    name: `${formData.title} ${formData.firstname} ${formData.lastname}`,
                    check: false
                };
            }



            let Data1;
            if (formData1.title === "Prefer Not to Say") {
                Data1 = {
                    ...formData1,
                    name: `${formData1.firstname} ${formData1.lastname}`,
                };
            } else {
                Data1 = {
                    ...formData1,
                    name: `${formData1.title} ${formData1.firstname} ${formData1.lastname}`,
                };
            }


            try {


                if (role === "consultant") {
                    let Data1 = {
                        id: id,
                        password: formData.password
                    }
                    const response1 = await axios.post("/users/verifyPassword", Data1);

                    if (response1?.status === 200) {
                        const response = await axios.patch(`/users/${id}`, Data, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        });
                        if (response?.status === 200 || response?.status === 201) {
                            const token = JSON.parse(sessionStorage.getItem("data"))
                                ?.userData?._id;
                            const data = response?.data?.data?.userData;
                            const userData = {
                                userData: data,
                                token,
                            };


                            toast.success(response.data.message);

                            if (response.data.data.role === "consultant") {
                                window.location.href = "/consultants";
                            } else {
                                window.location.href = "/consultants";

                            }
                            if (!state) {

                                if (response.data.data.role === "consultant") {
                                    window.location.reload();

                                    window.location.href = "/welcome";
                                } else {
                                    window.location.reload();
                                    window.location.href = "/consultants";

                                }

                            }

                        } else {
                            toast("Something went wrong. Please try again.");
                        }
                    } else {
                        toast.error("Input password is incorrect.")
                    }

                } else {
                    const response = await axios.patch(`/users/${id}`, Data1, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    if (response?.status === 200 || response?.status === 201) {
                        const token = JSON.parse(sessionStorage.getItem("data"))
                            ?.userData?._id;
                        const data = response?.data?.data?.userData;
                        const userData = {
                            userData: data,
                            token,
                        };


                        toast.success(response.data.message);

                        if (response.data.data.role === "consultant") {
                            window.location.href = "/consultants";
                        } else {
                            window.location.href = "/consultants";

                        }
                        if (!state) {
                            console.log(response.data.data.role)
                            if (response.data.data.role === "consultant") {
                                window.location.reload();

                                window.location.href = "/welcome";
                            } else {
                                window.location.reload();
                                window.location.href = "/consultants";

                            }

                        }

                    } else {
                        toast("Something went wrong. Please try again.");
                    }

                }


            } catch (error) {
                console.error("Error saving profile:", error);
                toast.error(error.response.data.message);

            }
        }
    };

    return (
        <>
            {
                window.innerWidth < 500 ? (
                    <div className="text-center text-white p-4 bg-red-500">
                        Sorry! this portal is only operatable through desktop devices.
                    </div>
                ) : (
                    <div className="max-w-4xl ms-14 mt-10">
                        <div className="max-w-4xl ms-14 mt-10">
                            <ToastContainer />
                            <h2 className="mx-auto text-center text-3xl font-bold">
                                My Profile
                            </h2>
                            <div className="w-4/5 my-3 mx-auto flex justify-center text-center" style={{ width: "60%" }}>
                                {role === "Admin" ? (
                                    <p>
                                        Some additional information is required for you to
                                        refer patients to us online.Please fill out all required fields below to be able to make a patient referral immediately.
                                    </p>
                                ) : (
                                    <>
                                        <p>
                                            Some additional information is required for you to
                                            refer patients to us online. Please fill out all required fields below to be able to make a patient referral immediately.
                                        </p>
                                    </>
                                )}
                            </div>
                            <form className="grid grid-cols-1 md:grid-cols-3 gap-10">
                                {/* First Column */}
                                <div>

                                    <div style={{ position: "relative" }}>
                                        <span
                                            className="text-red-500 ml-2"
                                            style={{
                                                position: "absolute",
                                                top: "",
                                                right: "",
                                            }}
                                        >
                                            *
                                        </span>
                                        <select
                                            name="title"
                                            value={formData.title || ""}
                                            onChange={handleInputChange}
                                            placeholder="Select Title"
                                            className=" py-2 my-3 px-4 w-full rounded-lg border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                            style={{ marginLeft: "1rem" }}
                                        >
                                            <option value="" disabled>
                                                Title
                                            </option>
                                            <option value="Mr">Mr</option>
                                            <option value="Miss">Miss</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Dr">Dr</option>
                                            <option value="Prof">Prof</option>
                                            <option value="Dame">Dame</option>
                                            <option value="Lady">Lady</option>
                                            <option value="Lord">Lord</option>
                                            <option value="Sir">Sir</option>


                                            <option value="Prefer Not to Say">
                                                Prefer Not to Say
                                            </option>
                                        </select>
                                    </div>

                                    <div style={{ position: "relative" }}>
                                        <span
                                            className="text-red-500 ml-2"
                                            style={{
                                                position: "absolute",
                                                top: "",
                                                right: "",
                                            }}
                                        >
                                            *
                                        </span>

                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            style={{ marginLeft: "1rem" }}
                                            className="py-2 border rounded-lg my-3 px-4 w-full"
                                            name="firstname"
                                            value={formData.firstname}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <span
                                        className="text-red-500"
                                        style={{
                                            position: "absolute",
                                            top: "-0.1rem",
                                            right: "16rem",
                                        }}
                                    >
                                        *
                                    </span>
                                    <div style={{ position: "relative" }}>
                                        <span
                                            className="text-red-500 ml-2"
                                            style={{
                                                position: "absolute",
                                                top: "",
                                                right: "",
                                            }}
                                        >
                                            *
                                        </span>

                                        <input
                                            type="text"
                                            placeholder="Last Name"
                                            style={{ marginLeft: "1rem" }}
                                            className="py-2 border rounded-lg my-3 px-4 w-full"
                                            name="lastname"
                                            value={formData.lastname}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div style={{ position: "relative" }}>
                                        <span
                                            className="text-red-500 ml-2"
                                            style={{
                                                position: "absolute",
                                                top: "",
                                                right: "",
                                            }}
                                        >
                                            *
                                        </span>
                                    </div>

                                    <input
                                        type="text"
                                        placeholder="Job Title"
                                        ssss
                                        style={{ marginLeft: "1rem" }}
                                        className="py-2 border rounded-lg my-3 px-4 w-full"
                                        name="jobTitle"
                                        value={formData.jobTitle}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Postcode"
                                        style={{ marginLeft: "1rem" }}
                                        className="py-2 border rounded-lg my-3 px-4 w-full"
                                        name="postcode"
                                        value={formData.postcode}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Referrer's mobile"
                                        className="py-2 border rounded-lg my-3 px-4 w-full"
                                        name="landline"
                                        style={{ marginLeft: "1rem" }}
                                        value={formData.landline}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                {/* Second Column */}
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Primary Practice Name"
                                        className="py-2 border rounded-lg my-3 px-4 w-full"
                                        name="primaryPracticeName"
                                        value={formData.primaryPracticeName}
                                        onChange={handleInputChange}
                                    />
                                    <div style={{ position: "relative" }}>
                                        <span
                                            className="text-red-500"
                                            style={{
                                                position: "absolute",
                                                top: "",  // You can set the desired top position
                                                left: "-7px",  // Adjust the value to move it to the right
                                            }}
                                        >
                                            *
                                        </span>


                                        <input
                                            type="text"
                                            placeholder="GMC/AHP Registration Number"
                                            className="py-2 border rounded-lg my-3 px-4 w-full"
                                            name="registrationNumber"
                                            value={formData.registrationNumber}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Street"
                                        className="py-2 border rounded-lg my-3 px-4 w-full"
                                        name="street"
                                        value={formData.street}
                                        onChange={handleInputChange}
                                    />
                                    <div style={{ position: "relative" }}>
                                        <span
                                            className="text-red-500"
                                            style={{
                                                position: "absolute",
                                                top: "",  // You can set the desired top position
                                                left: "-7px",  // Adjust the value to move it to the right
                                            }}
                                        >
                                            *
                                        </span>
                                        <input
                                            title="Please Contact Admin for Email Change."
                                            type="email"
                                            placeholder="Referrer's email"
                                            name="email"
                                            className="py-2 border rounded-lg my-3 px-4 w-full"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            disabled={role === "consultant" ? true : false}
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevents the default behavior (opening mailto link)
                                                if (formData.email) {
                                                    // Handle any other click logic specific to the input
                                                }
                                            }}
                                        />

                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Town/City"
                                        className="py-2 border rounded-lg my-3 px-4 w-full"
                                        name="townCity"
                                        value={formData.townCity}
                                        onChange={handleInputChange}
                                    />
                                    <div style={{ position: "relative" }}>
                                        {role === "consultant" && (
                                            <span
                                                className="text-red-500"
                                                style={{
                                                    position: "absolute",
                                                    top: "",  // You can set the desired top position
                                                    left: "-7px",  // Adjust the value to move it to the right
                                                }}
                                            >
                                                *
                                            </span>
                                        )}

                                        {role === "consultant" && (
                                            <>
                                                {showPass ? (
                                                    <FaEyeSlash
                                                        className=" absolute right-1 w-6 h-6 mr-2 mt-5 text-gray-400 hover:shake"
                                                        onClick={() => setShowPass(!showPass)}
                                                    />
                                                ) : (
                                                    <FaEye
                                                        className=" absolute right-1 w-6 h-6 mr-2 mt-5 text-gray-400 hover:shake"
                                                        onClick={() => setShowPass(!showPass)}
                                                    />
                                                )}

                                                <input
                                                    type={showPass ? "text" : "password"}
                                                    placeholder="Password"
                                                    value={formData.password}
                                                    name="password"
                                                    className="py-2  border rounded-lg my-3 px-4 w-full"
                                                    onChange={handleInputChange}
                                                />
                                            </>
                                        )}
                                    </div>

                                </div>

                                {/* Third Column */}

                                <div className="flex flex-col items-center justify-start">
                                    {/* <p>Click below to update image</p> */}
                                    <label
                                        htmlFor="image"
                                        className={`inline cursor-pointer mt-4 ${imageChange ? 'hidden' : 'bg-[#2CA1C8]'
                                            }`}
                                        style={{ background: '#2CA1C8', color: 'white' }}
                                        onClick={handleClickImage}
                                    >
                                        {/* Your SVG Icon */}
                                    </label>
                                    <input
                                        type="file"
                                        id="image"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="hidden"
                                        ref={fileInputRef}
                                    />

                                    <p className="flex text-center font-bold text-[16px] mt-[-10px]">Click on the icon below to
                                        update your profile photo:</p>
                                    <div className="flex justify-start">
                                        {!imageChange ? (
                                            <div
                                                className="mt-3 mb-4 max-w-full max-w-full w-[75px] h-[75px] rounded-full"
                                                style={{ borderRadius: '100%', width: '75px', height: '75px', marginBottom: '30px' }}
                                                onClick={handleClickImage}
                                            >
                                                <img
                                                    src={formData?.image ? formData?.image : defaultImage}
                                                    alt="Selected"
                                                    className="rounded-full mt-3 max-w-full max-h-full h-auto w-[75px] h-[75px]"
                                                    style={{ borderRadius: '100%', width: '75px', height: '75px', marginBottom: '30px' }}
                                                />
                                            </div>
                                        ) : imageChange ? (
                                            <div className="">
                                                <img
                                                    src={formData?.image ? URL.createObjectURL(formData?.image) : defaultImage}
                                                    alt="Selected"
                                                    className="mt-4 max-w-full h-auto w-[75px] h-[75px]"
                                                    style={{ borderRadius: '50px', cursor: 'pointer', width: '75px', height: '75px' }}
                                                    onClick={handleClickImage}
                                                />
                                                {/* <button onClick={handleRemoveImage}>Remove Image</button> */}
                                            </div>
                                        ) : null}
                                    </div>


                                    <div style={{ position: "relative" }}>
                                        <span
                                            className="text-red-500"
                                            style={{
                                                position: "absolute",
                                                top: "-13px",  // You can set the desired top position
                                                left: "10px",  // Adjust the value to move it to the right
                                            }}
                                        >
                                            *
                                        </span>

                                        <input
                                            type="text"
                                            placeholder="Secretary Name"
                                            style={{ marginLeft: "1rem" }}
                                            className="py-2 border rounded-lg mb-3 px-4 w-full"
                                            name="secretaryName"
                                            value={formData.secretaryName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div style={{ position: "relative" }}>
                                        <span
                                            className="text-red-500"
                                            style={{
                                                position: "absolute",
                                                top: "",  // You can set the desired top position
                                                left: "10px",  // Adjust the value to move it to the right
                                            }}
                                        >
                                            *
                                        </span>

                                        <input
                                            type="text"
                                            placeholder="Secretary Telephone"
                                            style={{ marginLeft: "1rem" }}
                                            className="py-2 border rounded-lg my-3 px-4 w-full"
                                            name="secretaryTelephone"
                                            value={formData.secretaryTelephone}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div style={{ position: "relative" }}>
                                        <span
                                            className="text-red-500"
                                            style={{
                                                position: "absolute",
                                                top: "",  // You can set the desired top position
                                                left: "10px",  // Adjust the value to move it to the right
                                            }}
                                        >
                                            *
                                        </span>

                                        <input
                                            type="email"
                                            placeholder="Secretary Email"
                                            style={{ marginLeft: "1rem" }}
                                            className="py-2 border rounded-lg my-3 px-4 w-full"
                                            name="secretaryEmail"
                                            value={formData.secretaryEmail}
                                            onChange={handleInputChange}
                                            required

                                        />
                                    </div>
                                    <div className="flex mt-3 mr-3">

                                        {role !== "consultant" && (
                                            <>

                                                {formData.statusId === "inactive" ? <h2 className="font-bold text-[16px]">Unblock</h2> : <h2 className="font-bold text-[16px]">Block</h2>}
                                                < Switch
                                                    checked={block}
                                                    className={`ml-2  ${block ? '' : 'switch-off'}`}
                                                    onChange={toogler}
                                                    style={{ backgroundColor: block ? 'LightSkyBlue' : 'gray' }}
                                                />
                                            </>
                                        )}

                                    </div>


                                    {/* <Switch className="mt-3" onClick={toogler} /> */}
                                </div>
                                <div style={{ position: "relative" }}>
                                    <span
                                        className="ml-6 font-bold"
                                    >
                                        Radiologist allocation
                                    </span>

                                    <span
                                        className="text-red-500 ml-2"
                                        style={{
                                            position: "absolute",
                                            top: "",
                                            right: "",
                                        }}
                                    >
                                        *
                                    </span>

                                    <select
                                        name="preferred_radiologists"
                                        value={formData.preferred_radiologists || ""}
                                        onChange={handleInputChange}
                                        placeholder="Select Title"

                                        className=" py-2 my-3 px-2 w-full rounded-lg border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow"
                                        style={{ marginLeft: "1rem" }}
                                    >
                                        <option value="Allow_UME_to_allocate_for_me">Allow UME to allocate for me</option>
                                        <option value="Specify">Specify</option>
                                    </select>
                                </div>

                                {formData.preferred_radiologists == "Specify"?
                                    <div style={{ position: "relative" }}>
                                        <span
                                            className="ml-6 font-bold"
                                        >

                                        </span>
                                        <span
                                            className="text-red-500 ml-2"
                                            style={{
                                                position: "absolute",
                                                top: "",
                                                right: "",
                                            }}
                                        >
                                            *
                                        </span>

                                        {inputs && inputs?.map((input, index) => (
                                            <div key={index} className="flex justify-center items-center">
                                                <input
                                                    type="text"
                                                    placeholder="Enter radiologist name …"
                                                    style={{ marginLeft: "1rem" }}
                                                    className="py-2 border rounded-lg my-3  px-4 w-full"
                                                    required
                                                    value={input}
                                                    onChange={(e) => handleInputChanges(index, e.target.value)}
                                                />
                                                {index !== 0 && inputs?.length > 1 && (
                                                    <svg className="ml-4" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => removeInput(index)}>
                                                        <g clip-path="url(#clip0_1942_6267)">
                                                            <path d="M28.8008 20C28.8008 20.44 28.4408 20.8 28.0008 20.8H20.0008C20.0008 20.8 20.0408 20.8 19.6008 20.8H19.2008H12.0008C11.5608 20.8 11.2008 20.44 11.2008 20C11.2008 19.56 11.5608 19.2 12.0008 19.2H20.8008H28.0008C28.4408 19.2 28.8008 19.56 28.8008 20ZM35.2008 20C35.2008 28.4 28.4008 35.2 20.0008 35.2C11.6008 35.2 4.80078 28.4 4.80078 20C4.80078 11.6 11.6008 4.80005 20.0008 4.80005C28.4008 4.80005 35.2008 11.6 35.2008 20ZM33.6008 20C33.6008 12.48 27.5208 6.40005 20.0008 6.40005C12.4808 6.40005 6.40078 12.48 6.40078 20C6.40078 27.52 12.4808 33.6 20.0008 33.6C27.5208 33.6 33.6008 27.52 33.6008 20Z" fill="#2CA1C8" />
                                                            <path d="M601.601 -484V189.6H-111.999V-484H601.601ZM604.801 -487.2H-115.199V192.8H604.801V-487.2Z" fill="#0000FF" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1942_6267">
                                                                <rect width="40" height="40" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                )}
                                                {index === 0 ? <svg className="ml-4" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={addInput}>
                                                    <g clip-path="url(#clip0_1942_6264)">
                                                        <path d="M28.8008 20C28.8008 20.44 28.4408 20.8 28.0008 20.8H20.8008V28C20.8008 28.4401 20.4408 28.8 20.0008 28.8C19.5608 28.8 19.2008 28.4401 19.2008 28V20.8H12.0008C11.5608 20.8 11.2008 20.44 11.2008 20C11.2008 19.56 11.5608 19.2 12.0008 19.2H19.2008V12C19.2008 11.56 19.5608 11.2 20.0008 11.2C20.4408 11.2 20.8008 11.56 20.8008 12V19.2H28.0008C28.4408 19.2 28.8008 19.56 28.8008 20ZM35.2008 20C35.2008 28.4 28.4008 35.2 20.0008 35.2C11.6008 35.2 4.80078 28.4 4.80078 20C4.80078 11.6 11.6008 4.80005 20.0008 4.80005C28.4008 4.80005 35.2008 11.6 35.2008 20ZM33.6008 20C33.6008 12.48 27.5208 6.40005 20.0008 6.40005C12.4808 6.40005 6.40078 12.48 6.40078 20C6.40078 27.52 12.4808 33.6 20.0008 33.6C27.5208 33.6 33.6008 27.52 33.6008 20Z" fill="#2CA1C8" />
                                                        <path d="M601.601 -484V189.6H-111.999V-484H601.601ZM604.801 -487.2H-115.199V192.8H604.801V-487.2Z" fill="#0000FF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1942_6264">
                                                            <rect width="40" height="40" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg> : null}
                                            </div>
                                        ))}



                                    </div> : null}


                            </form>


                            {/* Submit Button */}
                            <div className="flex justify-end mt-4 md:mr-24">
                                <button
                                    onClick={handleSubmit}
                                    className="bg-[#003942] text-white px-4 py-2 rounded-md" style={{ marginBottom: "50px" }}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
};

export default Profile;
