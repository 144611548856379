import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import ForgotPassword from './pages/ForgotPassword';
import OTP from './pages/OTP';
import { createBrowserHistory } from 'history';


import React, { useState, useEffect } from 'react';

const withMobileDeviceWarning = (WrappedComponent) => {
  const WithMobileDeviceWarning = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const checkDeviceType = () => {
        setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
      };

      checkDeviceType();

      window.addEventListener('resize', checkDeviceType);

      return () => {
        window.removeEventListener('resize', checkDeviceType);
      };
    }, []);

    return (
      <div>
        {isMobile && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black overlay
              zIndex: '9999',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>
              <p>We strive to make your experience as seamless as possible. For the best user experience, we kindly ask you to switch to a computer or desktop.</p>
            </div>
          </div>
        )}
        <WrappedComponent />
      </div>
    );
  };

  return WithMobileDeviceWarning;
};



function getToken() {
  const tokenString = sessionStorage.getItem('data');
  const data = JSON.parse(tokenString);
  return data?.token || null;
}

function App() {
  const history = createBrowserHistory();
  const token = getToken();

  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              token ? (
                <Navigate to="/" replace />
              ) : (
                <Login />
              )
            }
          />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/Otp-verification" element={<OTP />} />
          <Route path="/*" element={<Dashboard />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default withMobileDeviceWarning(App);
