import React, { useState, useRef, useEffect } from "react";
import { CiLogin } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import UserAvatar from "../Assets/images/user-avatar-32.png";

function DropdownProfile({ align }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [image, setImage] = useState("");
  const [userName, setUserName] = useState("");

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const navigate = useNavigate();
  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });
  useEffect(() => {
    const name = JSON.parse(sessionStorage.getItem("data"))?.userData?.name;

    console.log("bbbbbbbbbbbbbb", name);
    setUserName(name);
    const user = JSON.parse(sessionStorage.getItem("data"))?.userData;
    const image = user?.image;
    setImage(image);
  }, [sessionStorage]);
  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });
  const handleLogOut = () => {
    try {
      setTimeout(() => {
        // Navigate to the home page
        navigate("/");
       window.location.reload()
      }, 1000);
      // Remove user data from session storage
      sessionStorage.removeItem("data");

      // Dismiss any existing toasts
      toast.dismiss();

      // Show a success toast for logging out
      toast(`You have been successfully logged out.`);

      // Wait for a short duration (e.g., 1 second) before navigating to the home page
     
    } catch (error) {
      console.error("Error during logout:", error);
      // Handle any errors that may occur during logout
      // You might want to show an error toast or redirect to an error page
    }
  };

  return (
    <div className="relative inline-flex items-center">
      <ToastContainer />
      <button
        ref={trigger}
        className="inline-flex justify-right items-center group"
        aria-haspopup="true"
      >
        {/* <img
          className="w-8 h-8 rounded-full me-2"
          src={image ? image : UserAvatar}
          width="32"
          height="32"
          alt="User"
        /> */}
        <hr className="h-6 bg-black-200 dark:bg-black-700 border border-gray-400 me-3" />
        <div
          className="flex items-center justify-between gap-2 p-2 bg-[#EAF6FA] rounded-lg border border-transparent"
          onClick={() => handleLogOut()}
        >
          <CiLogin className="text-[#2CA1C8] text-xl" onClick={handleLogOut} />
          <span
            className="truncate ml-2 text-sm font-medium text-[#2CA1C8]"
            onClick={handleLogOut}
          >
            Logout
          </span>
        </div>
      </button>
    </div>
  );
}

export default DropdownProfile;
