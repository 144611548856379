

export const MRI_OPTIONS = [
    // {
    //     "id": 1,
    //     "label": "Contrast Required?",
    //     "value": "Contrast Required?",
    //     "sub_options": [
    //         { "id": 1, "label": "Yes", "value": "Yes", "main_category": "Contrast Required?" },
    //         { "id": 2, "label": "No", "value": "No", "main_category": "Contrast Required?" },
    //         { "id": 3, "label": "Unsure", "value": "Unsure", "main_category": "Contrast Required?" }
    //     ]
    // },
    {
        "id": 2,
        "label": "Abdomen",
        "value": "Abdomen",
        "sub_options": [
            { "id": 1, "label": "Abdomen", "value": "Abdomen", "main_category": "Abdomen" },
            { "id": 2, "label": "Abdominal Aorta", "value": "Abdominal Aorta", "main_category": "Abdomen" },
            { "id": 3, "label": "Liver", "value": "Liver", "main_category": "Abdomen" },
            { "id": 4, "label": "MRCP", "value": "MRCP", "main_category": "Abdomen" },
            { "id": 5, "label": "Pancreas", "value": "Pancreas", "main_category": "Abdomen" },
            { "id": 6, "label": "Proctogram", "value": "Proctogram", "main_category": "Abdomen" },
            { "id": 7, "label": "Prostate", "value": "Prostate", "main_category": "Abdomen" },
            { "id": 8, "label": "Small Bowel", "value": "Small Bowel", "main_category": "Abdomen" },
            { "id": 9, "label": "Small Bowel & Pelvis Soft Tissue", "value": "Small Bowel & Pelvis Soft Tissue", "main_category": "Abdomen" }
        ]
    },
    {
        "id": 3,
        "label": "Chest",
        "value": "Chest",
        "sub_options": [
            { "id": 1, "label": "Brachial Plexus", "value": "Brachial Plexus", "main_category": "Chest" },
            { "id": 2, "label": "Chest Wall", "value": "Chest Wall", "main_category": "Chest" },
            { "id": 3, "label": "Clavicle (Left)", "value": "Clavicle (Left)", "main_category": "Chest" },
            { "id": 4, "label": "Clavicle (Right)", "value": "Clavicle (Right)", "main_category": "Chest" },
            { "id": 5, "label": "Sternum", "value": "Sternum", "main_category": "Chest" },
            { "id": 6, "label": "Thoracic Inlet", "value": "Thoracic Inlet", "main_category": "Chest" },
            { "id": 7, "label": "Thoracic Outlet", "value": "Thoracic Outlet", "main_category": "Chest" }
        ]
    },
    {
        "id": 4,
        "label": "Head & Neck",
        "value": "Head & Neck",
        "sub_options": [
            { "id": 1, "label": "Brain", "value": "Brain", "main_category": "Head & Neck" },
            { "id": 2, "label": "Brain MRA", "value": "Brain MRA", "main_category": "Head & Neck" },
            { "id": 3, "label": "Brain MRV", "value": "Brain MRV", "main_category": "Head & Neck" },
            { "id": 4, "label": "Carotid", "value": "Carotid", "main_category": "Head & Neck" },
            { "id": 5, "label": "Dementia Protocol", "value": "Dementia Protocol", "main_category": "Head & Neck" },
            { "id": 6, "label": "Demyelination Protocol", "value": "Demyelination Protocol", "main_category": "Head & Neck" },
            { "id": 7, "label": "Epilepsy Protocol", "value": "Epilepsy Protocol", "main_category": "Head & Neck" },
            { "id": 8, "label": "IAMs", "value": "IAMs", "main_category": "Head & Neck" },
            { "id": 9, "label": "Meniere's", "value": "Meniere's", "main_category": "Head & Neck" },
            { "id": 10, "label": "Orbits", "value": "Orbits", "main_category": "Head & Neck" },
            { "id": 11, "label": "Pituitary", "value": "Pituitary", "main_category": "Head & Neck" },
            { "id": 12, "label": "Sinuses", "value": "Sinuses", "main_category": "Head & Neck" },
            { "id": 13, "label": "Soft Tissue Neck", "value": "Soft Tissue Neck", "main_category": "Head & Neck" },
            { "id": 14, "label": "Temporal Bones", "value": "Temporal Bones", "main_category": "Head & Neck" },
            { "id": 15, "label": "Temporomandibular Joints", "value": "Temporomandibular Joints", "main_category": "Head & Neck" },
            { "id": 16, "label": "Trigeminal Nerve", "value": "Trigeminal Nerve", "main_category": "Head & Neck" }
        ]
    },
    {
        "id": 5,
        "label": "Spine",
        "value": "Spine",
        "sub_options": [
            { "id": 1, "label": "Cervical", "value": "Cervical", "main_category": "Spine" },
            { "id": 2, "label": "Cervical & Oblique Sequences", "value": "Cervical & Oblique Sequences", "main_category": "Spine" },
            { "id": 3, "label": "Coccyx", "value": "Coccyx", "main_category": "Spine" },
            { "id": 4, "label": "Coronal Stir", "value": "Coronal Stir", "main_category": "Spine" },
            { "id": 5, "label": "Lumbar", "value": "Lumbar", "main_category": "Spine" },
            { "id": 6, "label": "Lumbar Plexus", "value": "Lumbar Plexus", "main_category": "Spine" },
            { "id": 7, "label": "Lumbar with MARS protocol", "value": "Lumbar with MARS protocol", "main_category": "Spine" },
            { "id": 8, "label": "Sacral", "value": "Sacral", "main_category": "Spine" },
            { "id": 9, "label": "Sacroiliac Joints", "value": "Sacroiliac Joints", "main_category": "Spine" },
            { "id": 10, "label": "Thoracic", "value": "Thoracic", "main_category": "Spine" },
            { "id": 11, "label": "Whole Spine", "value": "Whole Spine", "main_category": "Spine" }
        ]
    },
    {
        "id": 6,
        "label": "Cardiac",
        "value": "Cardiac",
        "sub_options": [
            { "id": 1, "label": "CMR", "value": "CMR", "main_category": "Cardiac" },
            { "id": 2, "label": "Hypertension Protocol", "value": "Hypertension Protocol", "main_category": "Cardiac" },
            { "id": 3, "label": "MyoStrain", "value": "MyoStrain", "main_category": "Cardiac" },
            { "id": 4, "label": "MyoStress", "value": "MyoStress", "main_category": "Cardiac" },
            { "id": 5, "label": "Stress CMR", "value": "Stress CMR", "main_category": "Cardiac" }
        ]
    },
    {
        "id": 7,
        "label": "Upper Limb",
        "value": "Upper Limb",
        "sub_options": [
            { "id": 1, "label": "Elbow (Left)", "value": "Elbow (Left)", "main_category": "Upper Limb" },
            { "id": 2, "label": "Elbow (Left) Arthrogram", "value": "Elbow (Left) Arthrogram", "main_category": "Upper Limb" },
            { "id": 3, "label": "Elbow (Right)", "value": "Elbow (Right)", "main_category": "Upper Limb" },
            { "id": 4, "label": "Elbow (Right) Arthrogram", "value": "Elbow (Right) Arthrogram", "main_category": "Upper Limb" },
            { "id": 5, "label": "Fingers (Left)", "value": "Fingers (Left)", "main_category": "Upper Limb" },
            { "id": 6, "label": "Fingers (Right)", "value": "Fingers (Right)", "main_category": "Upper Limb" },
            { "id": 7, "label": "Forearm Lower (Left)", "value": "Forearm Lower (Left)", "main_category": "Upper Limb" },
            { "id": 8, "label": "Forearm Lower (Right)", "value": "Forearm Lower (Right)", "main_category": "Upper Limb" },
            { "id": 9, "label": "Forearm Upper (Left)", "value": "Forearm Upper (Left)", "main_category": "Upper Limb" },
            { "id": 10, "label": "Forearm Upper (Right)", "value": "Forearm Upper (Right)", "main_category": "Upper Limb" },
            { "id": 11, "label": "Hand (Left)", "value": "Hand (Left)", "main_category": "Upper Limb" },
            { "id": 12, "label": "Hand (Right)", "value": "Hand (Right)", "main_category": "Upper Limb" },
            { "id": 13, "label": "Hindfoot (Left)", "value": "Hindfoot (Left)", "main_category": "Upper Limb" },
            { "id": 14, "label": "Hindfoot (Right)", "value": "Hindfoot (Right)", "main_category": "Upper Limb" },
            { "id": 15, "label": "Scapula (Left)", "value": "Scapula (Left)", "main_category": "Upper Limb" },
            { "id": 16, "label": "Scapula (Right)", "value": "Scapula (Right)", "main_category": "Upper Limb" },
            { "id": 17, "label": "Shoulder & Humerus (Left)", "value": "Shoulder & Humerus (Left)", "main_category": "Upper Limb" },
            { "id": 18, "label": "Shoulder & Humerus (Left) Arthrogram", "value": "Shoulder & Humerus (Left) Arthrogram", "main_category": "Upper Limb" },
            { "id": 19, "label": "Shoulder & Humerus (Right)", "value": "Shoulder & Humerus (Right)", "main_category": "Upper Limb" },
            { "id": 20, "label": "Shoulder & Humerus (Right) Arthrogram", "value": "Shoulder & Humerus (Right) Arthrogram", "main_category": "Upper Limb" },
            { "id": 21, "label": "Shoulder (Left)", "value": "Shoulder (Left)", "main_category": "Upper Limb" },
            { "id": 22, "label": "Shoulder (Left) Arthrogram", "value": "Shoulder (Left) Arthrogram", "main_category": "Upper Limb" },
            { "id": 23, "label": "Shoulder (Right)", "value": "Shoulder (Right)", "main_category": "Upper Limb" },
            { "id": 24, "label": "Shoulder (Right) Arthrogram", "value": "Shoulder (Right) Arthrogram", "main_category": "Upper Limb" },
            { "id": 25, "label": "Sternoclavicular Joints", "value": "Sternoclavicular Joints", "main_category": "Upper Limb" },
            { "id": 26, "label": "Thumb (Left)", "value": "Thumb (Left)", "main_category": "Upper Limb" },
            { "id": 27, "label": "Thumb (Right)", "value": "Thumb (Right)", "main_category": "Upper Limb" },
            { "id": 28, "label": "Wrist (Left)", "value": "Wrist (Left)", "main_category": "Upper Limb" },
            { "id": 29, "label": "Wrist (Right)", "value": "Wrist (Right)", "main_category": "Upper Limb" }
        ]
    },
    {
        "id": 8,
        "label": "Lower Limb",
        "value": "Lower Limb",
        "sub_options": [
            { "id": 1, "label": "Achilles (Left)", "value": "Achilles (Left)", "main_category": "Lower Limb" },
            { "id": 2, "label": "Achilles (Right)", "value": "Achilles (Right)", "main_category": "Lower Limb" },
            { "id": 3, "label": "Ankle (Left)", "value": "Ankle (Left)", "main_category": "Lower Limb" },
            { "id": 4, "label": "Ankle (Left) Arthrogram", "value": "Ankle (Left) Arthrogram", "main_category": "Lower Limb" },
            { "id": 5, "label": "Ankle (Right)", "value": "Ankle (Right)", "main_category": "Lower Limb" },
            { "id": 6, "label": "Ankle (Right) Arthrogram", "value": "Ankle (Right) Arthrogram", "main_category": "Lower Limb" },
            { "id": 7, "label": "Calf (Left)", "value": "Calf (Left)", "main_category": "Lower Limb" },
            { "id": 8, "label": "Calf (Right)", "value": "Calf (Right)", "main_category": "Lower Limb" },
            { "id": 9, "label": "Forefoot (Left)", "value": "Forefoot (Left)", "main_category": "Lower Limb" },
            { "id": 10, "label": "Forefoot (Right)", "value": "Forefoot (Right)", "main_category": "Lower Limb" },
            { "id": 11, "label": "Hindfoot (Left)", "value": "Hindfoot (Left)", "main_category": "Lower Limb" },
            { "id": 12, "label": "Hindfoot (Right)", "value": "Hindfoot (Right)", "main_category": "Lower Limb" },
            { "id": 13, "label": "Hip (Left)", "value": "Hip (Left)", "main_category": "Lower Limb" },
            { "id": 14, "label": "Hip (Left) Arthrogram", "value": "Hip (Left) Arthrogram", "main_category": "Lower Limb" },
            { "id": 15, "label": "Hip (Right)", "value": "Hip (Right)", "main_category": "Lower Limb" },
            { "id": 16, "label": "Hip (Right) Arthrogram", "value": "Hip (Right) Arthrogram", "main_category": "Lower Limb" },
            { "id": 17, "label": "Knee (Left)", "value": "Knee (Left)", "main_category": "Lower Limb" },
            { "id": 18, "label": "Knee (Left) Arthrogram", "value": "Knee (Left) Arthrogram", "main_category": "Lower Limb" },
            { "id": 19, "label": "Knee (Right)", "value": "Knee (Right)", "main_category": "Lower Limb" },
            { "id": 20, "label": "Knee (Right) Arthrogram", "value": "Knee (Right) Arthrogram", "main_category": "Lower Limb" }
        ]
    },
    {
        "id": 9,
        "label": "Pelvis",
        "value": "Pelvis",
        "sub_options": [
            { "id": 1, "label": "Groin", "value": "Groin", "main_category": "Pelvis" },
            { "id": 2, "label": "Hip (Left)", "value": "Hip (Left)", "main_category": "Pelvis" },
            { "id": 3, "label": "Hip (Left) Arthrogram", "value": "Hip (Left) Arthrogram", "main_category": "Pelvis" },
            { "id": 4, "label": "Hip (Right)", "value": "Hip (Right)", "main_category": "Pelvis" },
            { "id": 5, "label": "Hip (Right) Arthrogram", "value": "Hip (Right) Arthrogram", "main_category": "Pelvis" },
            { "id": 6, "label": "Pelvis MSK", "value": "Pelvis MSK", "main_category": "Pelvis" },
            { "id": 7, "label": "Pelvis Soft tissue", "value": "Pelvis Soft tissue", "main_category": "Pelvis" },
            { "id": 8, "label": "Sacroiliac Joints", "value": "Sacroiliac Joints", "main_category": "Pelvis" },
            { "id": 9, "label": "Sacrum", "value": "Sacrum", "main_category": "Pelvis" },
            { "id": 10, "label": "Uterus", "value": "Uterus", "main_category": "Pelvis" }
        ]
    },
    {
        "id": 10,
        "label": "Genitourinary",
        "value": "Genitourinary",
        "sub_options": [
            { "id": 1, "label": "Prostate (mpMRI)", "value": "Prostate (mpMRI)", "main_category": "Genitourinary" },
            { "id": 2, "label": "Renal", "value": "Renal", "main_category": "Genitourinary" },
            { "id": 3, "label": "Testes", "value": "Testes", "main_category": "Genitourinary" },
            { "id": 4, "label": "Uterus", "value": "Uterus", "main_category": "Genitourinary" }
        ]
    },
    {
        "id": 11,
        "label": "Other",
        "value": "Other",
        "sub_options": []
    },

]



export const CT_OPTIONS = [
    // {
    //     "id": 1,
    //     "label": "Contrast Required?",
    //     "value": "Contrast Required?",
    //     "sub_options": [
    //         { "id": 1, "label": "Yes", "value": "Yes", "main_category": "Contrast Required?" },
    //         { "id": 2, "label": "No", "value": "No", "main_category": "Contrast Required?" },
    //         { "id": 3, "label": "Unsure", "value": "Unsure", "main_category": "Contrast Required?" }
    //     ]
    // },
    {
        "id": 2,
        "label": "Abdomen",
        "value": "Abdomen",
        "sub_options": [
            { "id": 1, "label": "Abdomen", "value": "Abdomen", "main_category": "Abdomen" },
            { "id": 2, "label": "Abdominal Aorta", "value": "Abdominal Aorta", "main_category": "Abdomen" },
            { "id": 3, "label": "Colonography (Pneumocolon)", "value": "Colonography (Pneumocolon)", "main_category": "Abdomen" },
            { "id": 4, "label": "KUB", "value": "KUB", "main_category": "Abdomen" },
            { "id": 5, "label": "Liver", "value": "Liver", "main_category": "Abdomen" }
        ]
    },
    {
        "id": 3,
        "label": "Chest",
        "value": "Chest",
        "sub_options": [
            { "id": 1, "label": "Brachial Plexus", "value": "Brachial Plexus", "main_category": "Chest" },
            { "id": 2, "label": "Clavicle (Both)", "value": "Clavicle (Both)", "main_category": "Chest" },
            { "id": 3, "label": "Chest", "value": "Chest", "main_category": "Chest" },
            { "id": 4, "label": "Clavicle (Left)", "value": "Clavicle (Left)", "main_category": "Chest" },
            { "id": 5, "label": "Clavicle (Right)", "value": "Clavicle (Right)", "main_category": "Chest" },
            { "id": 6, "label": "CT Pulmonary Angiogram", "value": "CT Pulmonary Angiogram", "main_category": "Chest" },
            { "id": 7, "label": "Low dose CT Chest", "value": "Low dose CT Chest", "main_category": "Chest" },
            { "id": 8, "label": "Sternum", "value": "Sternum", "main_category": "Chest" },
            { "id": 9, "label": "Thoracic Inlet", "value": "Thoracic Inlet", "main_category": "Chest" },
            { "id": 10, "label": "Thoracic Outlet", "value": "Thoracic Outlet", "main_category": "Chest" }
        ]
    },
    {
        "id": 4,
        "label": "Head & Neck",
        "value": "Head & Neck",
        "sub_options": [
            { "id": 1, "label": "Brain", "value": "Brain", "main_category": "Head & Neck" },
            { "id": 2, "label": "Brain Circle of Willis (COW)", "value": "Brain Circle of Willis (COW)", "main_category": "Head & Neck" },
            { "id": 3, "label": "Carotid", "value": "Carotid", "main_category": "Head & Neck" },
            { "id": 4, "label": "Face", "value": "Face", "main_category": "Head & Neck" },
            { "id": 5, "label": "IAMs", "value": "IAMs", "main_category": "Head & Neck" },
            { "id": 6, "label": "Orbits", "value": "Orbits", "main_category": "Head & Neck" },
            { "id": 7, "label": "Sinuses", "value": "Sinuses", "main_category": "Head & Neck" },
            { "id": 8, "label": "Soft Tissue Neck", "value": "Soft Tissue Neck", "main_category": "Head & Neck" },
            { "id": 9, "label": "Temporal Bones", "value": "Temporal Bones", "main_category": "Head & Neck" },
            { "id": 10, "label": "Temporomandibular Joints", "value": "Temporomandibular Joints", "main_category": "Head & Neck" }
        ]
    },
    {
        "id": 5,
        "label": "Spine",
        "value": "Spine",
        "sub_options": [
            { "id": 1, "label": "Cervical", "value": "Cervical", "main_category": "Spine" },
            { "id": 2, "label": "Coccyx", "value": "Coccyx", "main_category": "Spine" },
            { "id": 3, "label": "Lumbar", "value": "Lumbar", "main_category": "Spine" },
            { "id": 4, "label": "Sacral", "value": "Sacral", "main_category": "Spine" },
            { "id": 5, "label": "Sacroiliac Joints", "value": "Sacroiliac Joints", "main_category": "Spine" },
            { "id": 6, "label": "Thoracic", "value": "Thoracic", "main_category": "Spine" },
            { "id": 7, "label": "Whole Spine", "value": "Whole Spine", "main_category": "Spine" }
        ]
    },
    {
        "id": 6,
        "label": "Cardiac",
        "value": "Cardiac",
        "sub_options": [
            { "id": 1, "label": "CT Calcium Score", "value": "CT Calcium Score", "main_category": "Cardiac" },
            { "id": 2, "label": "CT Coronary Angiogram", "value": "CT Coronary Angiogram", "main_category": "Cardiac" },
            { "id": 3, "label": "CT TAVI", "value": "CT TAVI", "main_category": "Cardiac" }
        ]
    },
    {
        "id": 7,
        "label": "Upper Limb",
        "value": "Upper Limb",
        "sub_options": [
            { "id": 1, "label": "Elbow (Left)", "value": "Elbow (Left)", "main_category": "Upper Limb" },
            { "id": 2, "label": "Elbow (Right)", "value": "Elbow (Right)", "main_category": "Upper Limb" },
            { "id": 3, "label": "Forearm Lower (Left)", "value": "Forearm Lower (Left)", "main_category": "Upper Limb" },
            { "id": 4, "label": "Forearm Lower (Right)", "value": "Forearm Lower (Right)", "main_category": "Upper Limb" },
            { "id": 5, "label": "Forearm Upper (Left)", "value": "Forearm Upper (Left)", "main_category": "Upper Limb" },
            { "id": 6, "label": "Forearm Upper (Right)", "value": "Forearm Upper (Right)", "main_category": "Upper Limb" },
            { "id": 7, "label": "Hand (Left)", "value": "Hand (Left)", "main_category": "Upper Limb" },
            { "id": 8, "label": "Hand (Right)", "value": "Hand (Right)", "main_category": "Upper Limb" },
            { "id": 9, "label": "Humerus (Left)", "value": "Humerus (Left)", "main_category": "Upper Limb" },
            { "id": 10, "label": "Humerus (Right)", "value": "Humerus (Right)", "main_category": "Upper Limb" },
            { "id": 11, "label": "Shoulder (Left)", "value": "Shoulder (Left)", "main_category": "Upper Limb" },
            { "id": 12, "label": "Shoulder (Right)", "value": "Shoulder (Right)", "main_category": "Upper Limb" },
            { "id": 13, "label": "Wrist (Left)", "value": "Wrist (Left)", "main_category": "Upper Limb" },
            { "id": 14, "label": "Wrist (Right)", "value": "Wrist (Right)", "main_category": "Upper Limb" },
            { "id": 15, "label": "Scapula", "value": "Scapula", "main_category": "Upper Limb" },
            { "id": 16, "label": "Sternoclavicular Joints", "value": "Sternoclavicular Joints", "main_category": "Upper Limb" },
            { "id": 17, "label": "Forefoot (Left)", "value": "Forefoot (Left)", "main_category": "Upper Limb" },
            { "id": 18, "label": "Forefoot (Right)", "value": "Forefoot (Right)", "main_category": "Upper Limb" },
            { "id": 19, "label": "Forefoot (Both)", "value": "Forefoot (Both)", "main_category": "Upper Limb" },
            { "id": 20, "label": "MSK Pelvis", "value": "MSK Pelvis", "main_category": "Upper Limb" }
        ]
    },
    {
        "id": 8,
        "label": "Lower Limb",
        "value": "Lower Limb",
        "sub_options": [
            { "id": 1, "label": "Ankle (Left)", "value": "Ankle (Left)", "main_category": "Lower Limb" },
            { "id": 2, "label": "Ankle (Right)", "value": "Ankle (Right)", "main_category": "Lower Limb" },
            { "id": 3, "label": "CT MAKO Hip (Left)", "value": "CT MAKO Hip (Left)", "main_category": "Lower Limb" },
            { "id": 4, "label": "CT MAKO Hip (Right)", "value": "CT MAKO Hip (Right)", "main_category": "Lower Limb" },
            { "id": 5, "label": "CT MAKO Knee (Left)", "value": "CT MAKO Knee (Left)", "main_category": "Lower Limb" },
            { "id": 6, "label": "CT MAKO Knee (Right)", "value": "CT MAKO Knee (Right)", "main_category": "Lower Limb" },
            { "id": 7, "label": "Femur (Left)", "value": "Femur (Left)", "main_category": "Lower Limb" },
            { "id": 8, "label": "Femur (Right)", "value": "Femur (Right)", "main_category": "Lower Limb" },
            { "id": 9, "label": "Foot (Left)", "value": "Foot (Left)", "main_category": "Lower Limb" },
            { "id": 10, "label": "Foot (Right)", "value": "Foot (Right)", "main_category": "Lower Limb" },
            { "id": 11, "label": "Hip (Left)", "value": "Hip (Left)", "main_category": "Lower Limb" },
            { "id": 12, "label": "Hip (Left) Arthrogram", "value": "Hip (Left) Arthrogram", "main_category": "Lower Limb" },
            { "id": 13, "label": "Hip (Right)", "value": "Hip (Right)", "main_category": "Lower Limb" },
            { "id": 14, "label": "Hip (Right) Arthrogram", "value": "Hip (Right) Arthrogram", "main_category": "Lower Limb" },
            { "id": 15, "label": "Knee (Left)", "value": "Knee (Left)", "main_category": "Lower Limb" },
            { "id": 16, "label": "Knee (Left) Arthrogram", "value": "Knee (Left) Arthrogram", "main_category": "Lower Limb" },
            { "id": 17, "label": "Knee (Right)", "value": "Knee (Right)", "main_category": "Lower Limb" },
            { "id": 18, "label": "Knee (Right) Arthrogram", "value": "Knee (Right) Arthrogram", "main_category": "Lower Limb" },
            { "id": 19, "label": "Tibia Fibula (Left)", "value": "Tibia Fibula (Left)", "main_category": "Lower Limb" },
            { "id": 20, "label": "Tibia Fibula (Right)", "value": "Tibia Fibula (Right)", "main_category": "Lower Limb" }
        ]
    },
    {
        "id": 9,
        "label": "Pelvis",
        "value": "Pelvis",
        "sub_options": [
            { "id": 1, "label": "Hip (Left)", "value": "Hip (Left)", "main_category": "Pelvis" },
            { "id": 2, "label": "Hip (Right)", "value": "Hip (Right)", "main_category": "Pelvis" },
            { "id": 3, "label": "Pelvis MSK", "value": "Pelvis MSK", "main_category": "Pelvis" },
            { "id": 4, "label": "Pelvis Soft tissue", "value": "Pelvis Soft tissue", "main_category": "Pelvis" },
            { "id": 5, "label": "Sacroiliac Joints", "value": "Sacroiliac Joints", "main_category": "Pelvis" },
            { "id": 6, "label": "Sacrum", "value": "Sacrum", "main_category": "Pelvis" }
        ]
    },
    {
        "id": 10,
        "label": "Other",
        "value": "Other",
        "sub_options": []
    },
]

export const X_RAY_OPTIONS = [{
    id: 1, label: "Abdomen", value: "Abdomen", sub_options: [
        {
            label: "Abdomen",
            value: "Abdomen",
            id: 1,
            main_category: "Abdomen"
        },
        {
            label: "KUB",
            value: "KUB",
            id: 2,
            main_category: "Abdomen"
        }
    ]
}, {
    id: 2, label: "Chest", value: "Chest", sub_options: [
        {
            label: "Chest (Pre-Employment)",
            value: "Chest (Pre-Employment)",
            id: 1,
            main_category: "Chest"
        },
        {
            label: "Chest (Visa)",
            value: "Chest (Visa)",
            id: 2,
            main_category: "Chest"
        },
        {
            label: "Chest PA",
            value: "Chest PA",
            id: 3,
            main_category: "Chest"
        },
        {
            label: "Clavicle (Left) 1 View",
            value: "Clavicle (Left) 1 View",
            id: 4,
            main_category: "Chest"
        },
        {
            label: "Clavicle (Right)",
            value: "Clavicle (Right)",
            id: 5,
            main_category: "Chest"
        },
        {
            label: "Clavicle 2 View",
            value: "Clavicle 2 View",
            id: 6,
            main_category: "Chest"
        },
        {
            label: "Sternum",
            value: "Sternum",
            id: 7,
            main_category: "Chest"
        },
        {
            label: "Thoracic Inlet",
            value: "Thoracic Inlet",
            id: 8,
            main_category: "Chest"
        },
        {
            label: "Thoracic Outlet",
            value: "Thoracic Outlet",
            id: 9,
            main_category: "Chest"
        }
    ]
}, {
    id: 3, label: "Head & Neck", value: "Head & Neck", sub_options: [
        {
            label: "Facial Bones 1 View",
            value: "Facial Bones 1 View",
            id: 1,
            main_category: "Head & Neck"
        },
        {
            label: "Facial Bones 2 View",
            value: "Facial Bones 2 View",
            id: 2,
            main_category: "Head & Neck"
        },
        {
            label: "Orbits 1 View",
            value: "Orbits 1 View",
            id: 3,
            main_category: "Head & Neck"
        },
        {
            label: "Orbits 2 Views",
            value: "Orbits 2 Views",
            id: 4,
            main_category: "Head & Neck"
        },
        {
            label: "Soft Tissue Neck",
            value: "Soft Tissue Neck",
            id: 5,
            main_category: "Head & Neck"
        },
        {
            label: "Temporomandibular Joints",
            value: "Temporomandibular Joints",
            id: 6,
            main_category: "Head & Neck"
        }
    ]
}, {
    id: 4, label: "Spine", value: "Spine", sub_options: [
        {
            label: "Cervical",
            value: "Cervical",
            id: 1,
            main_category: "Spine"
        },
        {
            label: "Coccyx",
            value: "Coccyx",
            id: 2,
            main_category: "Spine"
        },
        {
            label: "Lumbar",
            value: "Lumbar",
            id: 3,
            main_category: "Spine"
        },
        {
            label: "Sacral",
            value: "Sacral",
            id: 4,
            main_category: "Spine"
        },
        {
            label: "Sacroiliac Joints",
            value: "Sacroiliac Joints",
            id: 5,
            main_category: "Spine"
        },
        {
            label: "Thoracic",
            value: "Thoracic",
            id: 6,
            main_category: "Spine"
        },
        {
            label: "Whole Spine",
            value: "Whole Spine",
            id: 7,
            main_category: "Spine"
        }
    ]
},
{
    id: 5, label: "Upper Limb", value: "Upper Limb", sub_options: [
        {
            label: "Acromioclavicular Joints (Left)",
            value: "Acromioclavicular Joints (Left)",
            id: 1,
            main_category: "Upper Limb"
        },
        {
            label: "Acromioclavicular Joints (Right)",
            value: "Acromioclavicular Joints (Right)",
            id: 2,
            main_category: "Upper Limb"
        },
        {
            label: "Elbow (Left)",
            value: "Elbow (Left)",
            id: 3,
            main_category: "Upper Limb"
        },
        {
            label: "Elbow (Right)",
            value: "Elbow (Right)",
            id: 4,
            main_category: "Upper Limb"
        },
        {
            label: "Fingers (Left)",
            value: "Fingers (Left)",
            id: 5,
            main_category: "Upper Limb"
        },
        {
            label: "Fingers (Right)",
            value: "Fingers (Right)",
            id: 6,
            main_category: "Upper Limb"
        },
        {
            label: "Forearm (Left)",
            value: "Forearm (Left)",
            id: 7,
            main_category: "Upper Limb"
        },
        {
            label: "Forearm (Right)",
            value: "Forearm (Right)",
            id: 8,
            main_category: "Upper Limb"
        },
        {
            label: "Hand (Left)",
            value: "Hand (Left)",
            id: 9,
            main_category: "Upper Limb"
        },
        {
            label: "Hand (Right)",
            value: "Hand (Right)",
            id: 10,
            main_category: "Upper Limb"
        },
        {
            label: "Humerus (Left)",
            value: "Humerus (Left)",
            id: 11,
            main_category: "Upper Limb"
        },
        {
            label: "Humerus (Right)",
            value: "Humerus (Right)",
            id: 12,
            main_category: "Upper Limb"
        },
        {
            label: "Scaphoid (Left)",
            value: "Scaphoid (Left)",
            id: 13,
            main_category: "Upper Limb"
        },
        {
            label: "Scaphoid (Right)",
            value: "Scaphoid (Right)",
            id: 14,
            main_category: "Upper Limb"
        },
        {
            label: "Shoulder (Left)",
            value: "Shoulder (Left)",
            id: 15,
            main_category: "Upper Limb"
        },
        {
            label: "Shoulder (Right)",
            value: "Shoulder (Right)",
            id: 16,
            main_category: "Upper Limb"
        },
        {
            label: "Thumb (Left)",
            value: "Thumb (Left)",
            id: 17,
            main_category: "Upper Limb"
        },
        {
            label: "Thumb (Right)",
            value: "Thumb (Right)",
            id: 18,
            main_category: "Upper Limb"
        },
        {
            label: "Wrist (Left)",
            value: "Wrist (Left)",
            id: 19,
            main_category: "Upper Limb"
        },
        {
            label: "Wrist (Right)",
            value: "Wrist (Right)",
            id: 20,
            main_category: "Upper Limb"
        }
    ]
}, {
    id: 6, label: "Lower Limb", value: "Lower Limb", sub_options: [
        {
            label: "Ankle (Left) 2 View",
            value: "Ankle (Left) 2 View",
            id: 1,
            main_category: "Lower Limb"
        },
        {
            label: "Ankle (Left) 3 View",
            value: "Ankle (Left) 3 View",
            id: 2,
            main_category: "Lower Limb"
        },
        {
            label: "Ankle (Right) 2 View",
            value: "Ankle (Right) 2 View",
            id: 3,
            main_category: "Lower Limb"
        },
        {
            label: "Ankle (Right) 3 View",
            value: "Ankle (Right) 3 View",
            id: 4,
            main_category: "Lower Limb"
        },
        {
            label: "Ankle Weight Bearing (Left)",
            value: "Ankle Weight Bearing (Left)",
            id: 5,
            main_category: "Lower Limb"
        },
        {
            label: "Ankle Weight Bearing (Right)",
            value: "Ankle Weight Bearing (Right)",
            id: 6,
            main_category: "Lower Limb"
        },
        {
            label: "Calcaneum (Left)",
            value: "Calcaneum (Left)",
            id: 7,
            main_category: "Lower Limb"
        },
        {
            label: "Calcaneum (Right)",
            value: "Calcaneum (Right)",
            id: 8,
            main_category: "Lower Limb"
        },
        {
            label: "Femur (Left)",
            value: "Femur (Left)",
            id: 9,
            main_category: "Lower Limb"
        },
        {
            label: "Femur (Right)",
            value: "Femur (Right)",
            id: 10,
            main_category: "Lower Limb"
        },
        {
            label: "Foot (Left)",
            value: "Foot (Left)",
            id: 11,
            main_category: "Lower Limb"
        },
        {
            label: "Foot (Right)",
            value: "Foot (Right)",
            id: 12,
            main_category: "Lower Limb"
        },
        {
            label: "Hip (Left)",
            value: "Hip (Left)",
            id: 13,
            main_category: "Lower Limb"
        },
        {
            label: "Hip (Right)",
            value: "Hip (Right)",
            id: 14,
            main_category: "Lower Limb"
        },
        {
            label: "Knee (Left)",
            value: "Knee (Left)",
            id: 15,
            main_category: "Lower Limb"
        },
        {
            label: "Knee (Right)",
            value: "Knee (Right)",
            id: 16,
            main_category: "Lower Limb"
        },
        {
            label: "Tibia Fibula (Left)",
            value: "Tibia Fibula (Left)",
            id: 17,
            main_category: "Lower Limb"
        },
        {
            label: "Tibia Fibula (Right)",
            value: "Tibia Fibula (Right)",
            id: 18,
            main_category: "Lower Limb"
        },
        {
            label: "Toes (Left)",
            value: "Toes (Left)",
            id: 19,
            main_category: "Lower Limb"
        },
        {
            label: "Toes (Right)",
            value: "Toes (Right)",
            id: 20,
            main_category: "Lower Limb"
        }
    ],

}, {
    id: 7, label: "Pelvis", value: "Pelvis", sub_options: [
        {
            label: "Frog Legs (Paediatric)",
            value: "Frog Legs (Paediatric)",
            id: 1,
            main_category: "Pelvis"
        },
        {
            label: "Pelvis",
            value: "Pelvis",
            id: 2,
            main_category: "Pelvis"
        },
        {
            label: "Sacroiliac Joints",
            value: "Sacroiliac Joints",
            id: 3,
            main_category: "Pelvis"
        },
        {
            label: "Sacrum",
            value: "Sacrum",
            id: 4,
            main_category: "Pelvis"
        }
    ]
}, {
    "id": 8,
    "label": "Other",
    "value": "Other",
    "sub_options": []
},

]

export const USS = [{
    id: 1, label: "Doppler (Duplex)", value: "Doppler (Duplex)", sub_options: [
        {
            label: "Abdominal Aorta",
            value: "Abdominal Aorta",
            id: 1,
            main_category: "Doppler (Duplex)"
        },
        {
            label: "Carotid",
            value: "Carotid",
            id: 2,
            main_category: "Doppler (Duplex)"
        }
    ]
}, {
    id: 2, label: "Abdomen", value: "Abdomen", sub_options: [
        {
            id: 1,
            main_category: "Abdomen",
            label: "Abdominal Wall",
            value: "Abdominal Wall"
        },
        {
            id: 2,
            main_category: "Abdomen",
            label: "Renal Tract",
            value: "Renal Tract"
        },
        {
            id: 3,
            main_category: "Abdomen",
            label: "Transabdominal",
            value: "Transabdominal"
        }
    ]
}, {
    id: 3, label: "Chest", value: "Chest", sub_options: [{
        id: 1,
        main_category: "Chest",
        label: "Chest Wall",
        value: "Chest Wall"
    }]
}, {
    id: 4, label: "Head & Neck", value: "Head & Neck", sub_options: [
        {
            id: 1,
            main_category: "Head & Neck",
            label: "Face",
            value: "Face"
        },
        {
            id: 2,
            main_category: "Head & Neck",
            label: "Parathyroid",
            value: "Parathyroid"
        },
        {
            id: 3,
            main_category: "Head & Neck",
            label: "Soft Tissue Neck",
            value: "Soft Tissue Neck"
        },
        {
            id: 4,
            main_category: "Head & Neck",
            label: "Thyroid",
            value: "Thyroid"
        }
    ]

}, {
    id: 5, label: "Upper Limb", value: "Upper Limb", sub_options: [
        {
            id: 1,
            main_category: "Upper Limb",
            label: "Elbow (Left)",
            value: "Elbow (Left)"
        },
        {
            id: 2,
            main_category: "Upper Limb",
            label: "Elbow (Right)",
            value: "Elbow (Right)"
        },
        {
            id: 3,
            main_category: "Upper Limb",
            label: "Forearm Lower (Left)",
            value: "Forearm Lower (Left)"
        },
        {
            id: 4,
            main_category: "Upper Limb",
            label: "Forearm Lower (Right)",
            value: "Forearm Lower (Right)"
        },
        {
            id: 5,
            main_category: "Upper Limb",
            label: "Forearm Upper (Left)",
            value: "Forearm Upper (Left)"
        },
        {
            id: 6,
            main_category: "Upper Limb",
            label: "Forearm Upper (Right)",
            value: "Forearm Upper (Right)"
        },
        {
            id: 7,
            main_category: "Upper Limb",
            label: "Hand (Left)",
            value: "Hand (Left)"
        },
        {
            id: 8,
            main_category: "Upper Limb",
            label: "Hand (Right)",
            value: "Hand (Right)"
        },
        {
            id: 9,
            main_category: "Upper Limb",
            label: "Humerus (Left)",
            value: "Humerus (Left)"
        },
        {
            id: 10,
            main_category: "Upper Limb",
            label: "Humerus (Right)",
            value: "Humerus (Right)"
        },
        {
            id: 11,
            main_category: "Upper Limb",
            label: "Shoulder (Left)",
            value: "Shoulder (Left)"
        },
        {
            id: 12,
            main_category: "Upper Limb",
            label: "Shoulder (Right)",
            value: "Shoulder (Right)"
        },
        {
            id: 13,
            main_category: "Upper Limb",
            label: "Wrist (Left)",
            value: "Wrist (Left)"
        },
        {
            id: 14,
            main_category: "Upper Limb",
            label: "Wrist (Right)",
            value: "Wrist (Right)"
        }
    ]
}, {
    id: 6, label: "Lower Limb", value: "Lower Limb", sub_options: [
        {
            id: 1,
            main_category: "Lower Limb",
            label: "Ankle (Left)",
            value: "Ankle (Left)"
        },
        {
            id: 2,
            main_category: "Lower Limb",
            label: "Ankle (Right)",
            value: "Ankle (Right)"
        },
        {
            id: 3,
            main_category: "Lower Limb",
            label: "Femur (Left)",
            value: "Femur (Left)"
        },
        {
            id: 4,
            main_category: "Lower Limb",
            label: "Femur (Right)",
            value: "Femur (Right)"
        },
        {
            id: 5,
            main_category: "Lower Limb",
            label: "Foot (Left)",
            value: "Foot (Left)"
        },
        {
            id: 6,
            main_category: "Lower Limb",
            label: "Foot (Right)",
            value: "Foot (Right)"
        },
        {
            id: 7,
            main_category: "Lower Limb",
            label: "Hip (Left)",
            value: "Hip (Left)"
        },
        {
            id: 8,
            main_category: "Lower Limb",
            label: "Hip (Left) Arthrogram",
            value: "Hip (Left) Arthrogram"
        },
        {
            id: 9,
            main_category: "Lower Limb",
            label: "Hip (Right)",
            value: "Hip (Right)"
        },
        {
            id: 10,
            main_category: "Lower Limb",
            label: "Hip (Right) Arthrogram",
            value: "Hip (Right) Arthrogram"
        },
        {
            id: 11,
            main_category: "Lower Limb",
            label: "Knee (Left)",
            value: "Knee (Left)"
        },
        {
            id: 12,
            main_category: "Lower Limb",
            label: "Knee (Left) Arthrogram",
            value: "Knee (Left) Arthrogram"
        },
        {
            id: 13,
            main_category: "Lower Limb",
            label: "Knee (Right)",
            value: "Knee (Right)"
        },
        {
            id: 14,
            main_category: "Lower Limb",
            label: "Knee (Right) Arthrogram",
            value: "Knee (Right) Arthrogram"
        },
        {
            id: 15,
            main_category: "Lower Limb",
            label: "Tibia Fibula (Left)",
            value: "Tibia Fibula (Left)"
        },
        {
            id: 16,
            main_category: "Lower Limb",
            label: "Tibia Fibula (Right)",
            value: "Tibia Fibula (Right)"
        }
    ]
}, {
    id: 7, label: "Pelvis", value: "Pelvis", sub_options: [
        {
            id: 1,
            main_category: "Pelvis",
            label: "Pelvis",
            value: "Pelvis"
        },
        {
            id: 2,
            main_category: "Pelvis",
            label: "Pelvis Soft Tissue",
            value: "Pelvis Soft Tissue"
        },
        {
            id: 3,
            main_category: "Pelvis",
            label: "Sacroiliac Joints",
            value: "Sacroiliac Joints"
        },
        {
            id: 4,
            main_category: "Pelvis",
            label: "Sacrum",
            value: "Sacrum"
        },
        {
            id: 5,
            main_category: "Pelvis",
            label: "Testes",
            value: "Testes"
        },
        {
            id: 6,
            main_category: "Pelvis",
            label: "Transrectal",
            value: "Transrectal"
        },
        {
            id: 7,
            main_category: "Pelvis",
            label: "Transvaginal",
            value: "Transvaginal"
        }
    ]

}, {
    "id": 8,
    "label": "Other",
    "value": "Other",
    "sub_options": []
},
]

export const INJECTIONS = [{
    id: 1, label: "CT Guided", value: "CT Guided", sub_options: [
        {
            label: "Epidural Injection (Cervical)",
            value: "Epidural Injection (Cervical)",
            id: 1,
            main_category: "CT Guided"
        },
        {
            label: "Epidural Injection (Lumbar)",
            value: "Epidural Injection (Lumbar)",
            id: 2,
            main_category: "CT Guided"
        },
        {
            label: "Facet Joint Injection (Caudal)",
            value: "Facet Joint Injection (Caudal)",
            id: 3,
            main_category: "CT Guided"
        },
        {
            label: "Facet Joint Injection (Cervical)",
            value: "Facet Joint Injection (Cervical)",
            id: 4,
            main_category: "CT Guided"
        },
        {
            label: "Facet Joint Injection (Lumbar)",
            value: "Facet Joint Injection (Lumbar)",
            id: 5,
            main_category: "CT Guided"
        },
        {
            label: "Nerve Root Block (Caudal)",
            value: "Nerve Root Block (Caudal)",
            id: 6,
            main_category: "CT Guided"
        },
        {
            label: "Nerve Root Block (Cervical)",
            value: "Nerve Root Block (Cervical)",
            id: 7,
            main_category: "CT Guided"
        },
        {
            label: "Nerve Root Block (Lumbar)",
            value: "Nerve Root Block (Lumbar)",
            id: 8,
            main_category: "CT Guided"
        },
        {
            label: "Nerve Root Block (Thoracic)",
            value: "Nerve Root Block (Thoracic)",
            id: 9,
            main_category: "CT Guided"
        },
        {
            label: "Percutaneous Thermal Coagulation Of Mass",
            value: "Percutaneous Thermal Coagulation Of Mass",
            id: 10,
            main_category: "CT Guided"
        },
        {
            label: "Sacroiliac Joint Injection",
            value: "Sacroiliac Joint Injection",
            id: 11,
            main_category: "CT Guided"
        }
    ]
},

{
    id: 2, label: "Fluoroscopy", value: "Fluoroscopy", sub_options: [
        {
            label: "Epidural Injection (Cervical)",
            value: "Epidural Injection (Cervical)",
            id: 1,
            main_category: "Fluoroscopy"
        },
        {
            label: "Epidural Injection (Caudal)",
            value: "Epidural Injection (Caudal)",
            id: 2,
            main_category: "Fluoroscopy"
        },
        {
            label: "Epidural Injection (Lumbar)",
            value: "Epidural Injection (Lumbar)",
            id: 3,
            main_category: "Fluoroscopy"
        },
        {
            label: "Facet Joint Injection (Cervical)",
            value: "Facet Joint Injection (Cervical)",
            id: 4,
            main_category: "Fluoroscopy"
        },
        {
            label: "Facet Joint Injection (Lumbar)",
            value: "Facet Joint Injection (Lumbar)",
            id: 5,
            main_category: "Fluoroscopy"
        },
        {
            label: "Facet Joint Injection (Thoracic)",
            value: "Facet Joint Injection (Thoracic)",
            id: 6,
            main_category: "Fluoroscopy"
        },
        {
            label: "Nerve Root Block (Caudal)",
            value: "Nerve Root Block (Caudal)",
            id: 7,
            main_category: "Fluoroscopy"
        },
        {
            label: "Nerve Root Block (Cervical)",
            value: "Nerve Root Block (Cervical)",
            id: 8,
            main_category: "Fluoroscopy"
        },
        {
            label: "Nerve Root Block (Lumbar)",
            value: "Nerve Root Block (Lumbar)",
            id: 9,
            main_category: "Fluoroscopy"
        },
        {
            label: "Nerve Root Block (Thoracic)",
            value: "Nerve Root Block (Thoracic)",
            id: 10,
            main_category: "Fluoroscopy"
        },
        {
            label: "Neurolytic Root Block",
            value: "Neurolytic Root Block",
            id: 11,
            main_category: "Fluoroscopy"
        },
        {
            label: "Sacral Root Block",
            value: "Sacral Root Block",
            id: 12,
            main_category: "Fluoroscopy"
        },
        {
            label: "Sacroiliac Joint Injection",
            value: "Sacroiliac Joint Injection",
            id: 13,
            main_category: "Fluoroscopy"
        },
        {
            label: "Shoulder Hydrodistension",
            value: "Shoulder Hydrodistension",
            id: 14,
            main_category: "Fluoroscopy"
        }
    ]

}
, {
    id: 3, label: "Ultrasound Guided", value: "Ultrasound Guided", sub_options: [
        {
            label: "Injection +/- Aspiration",
            value: "Injection +/- Aspiration",
            id: 1,
            main_category: "Ultrasound Guided"
        }
    ]
}, {
    "id": 4,
    "label": "Other",
    "value": "Other",
    "sub_options": []
},
]

export const Modality = [
    { id: 1, name: "3T MRI", sub_options: true, sub_option: MRI_OPTIONS, checked: false, pairs: [{ value1: "", value2: "" }] },
    { id: 2, name: "CT", sub_options: true, sub_option: CT_OPTIONS, checked: false, pairs: [{ value1: "", value2: "" }] },
    { id: 3, name: "X-ray", sub_options: true, sub_option: X_RAY_OPTIONS, checked: false, pairs: [{ value1: "", value2: "" }] },
    { id: 4, name: "Ultrasound", sub_options: true, sub_option: USS, checked: false, pairs: [{ value1: "", value2: "" }] },
    { id: 5, name: "Injections", sub_options: true, sub_option: INJECTIONS, checked: false, pairs: [{ value1: "", value2: "" }] },
    // { id: 6, name: "Fluoroscopy", checked: false },
    { id: 7, name: "ECG", checked: false },
    { id: 8, name: "Echocardiogram", checked: false, },
    { id: 9, name: "Exercise stress ECG", checked: false, },
    { id: 10, name: "24-hour BP monitor", checked: false, },
    { id: 11, name: "Dobutamine stress echocardiogram", checked: false, },
    { id: 12, name: "Echocardiogram bubble study", checked: false },
    { id: 13, name: "Exercise stress echocardiogram", checked: false },
    { id: 14, name: "24-hour ECG monitor", checked: false },
    { id: 15, name: "48-hour ECG monitor", checked: false },
    { id: 16, name: "72-hour ECG monitor", checked: false },
    { id: 17, name: "7-day ECG monitor", checked: false },
    { id: 18, name: "14-day ECG monitor", checked: false }
];



export const steps = [
    {
        id: 1,
        options: Modality,
        field: "clinicalInfo",
        stepName: "Select Modality",
    },
    {
        id: 2,

        labels: [
            "Does the patient have a metal implant or pacemaker?",
            "Has the patient ever had an injury to the eye involving a metallic object?",
        ],
        fields: ["metalImplant", "eyeInjury",],
        stepName: "Safety",
    },
    {
        id: 3,
        labels: [
            "Does the patient have a metal implant or pacemaker?",
            "Has the patient ever had an injury to the eye involving a metallic object?",
        ],
        fields: ["metalImplant", "eyeInjury", "clinicalInfo",],
        stepName: "Summary",
    },
    {
        id: 4,
        stepName: "Finish",
    },
];


export function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
}

export function formatName(inputString) {
    if (inputString?.includes("_")) {
        return inputString.replace(/_/g, " ");
    } else {
        return inputString;
    }
}